<div
  class="outerCover"
  *ngIf="builderMode"
  [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'"
  [class.selectedNow]="selectedWidgetId === widgetMeta.id"
  [class.hoveredNow]="selectedWidgetId === widgetMeta.id? false: hoveredNow"
  (mouseenter)="widgetMouseenter()"
  (mouseleave)="widgetMouseleave()"
  (click)="onClick()"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
>
  <widget-drag-handle [class.invisible]="(selectedWidgetId !== widgetMeta.id && !hoveredNow)"></widget-drag-handle>

  <div class="innerCover" fxLayout="row" fxLayoutGap="5px">
    <inline-title [widgetMeta]="widgetMeta" [builderMode]="builderMode" [styles]="styles" (widgetTitleChanged)="newWidgetMeta.emit(widgetMeta)"></inline-title>
    <app-period-type [widgetMeta]="widgetMeta" (rangeValue)="inputDetected($event)"></app-period-type>
    <button style="align-self: center;" mat-icon-button [matMenuTriggerFor]="menutype" (click)="$event.stopPropagation()">
      <mat-icon>expand_more</mat-icon>
    </button>
  </div>

  <mat-menu #menutype hasBackdrop="true" yPosition="above" xPosition="before">
    @for (periodType of periodTypes; track $index) {
      <button mat-menu-item (click)="setPeriodType(periodType.value)">{{ periodType.name }}</button>
    }
  </mat-menu>
</div>

<div *ngIf="!builderMode && !widgetMeta.config?.hidden?.value" class="outerCover" appearance="standard"
  [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
>
  <div class="innerCover">
    <inline-title [widgetMeta]="widgetMeta" [builderMode]="builderMode" [styles]="styles"></inline-title>
    <app-period-type [widgetMeta]="widgetMeta" (rangeValue)="inputDetected($event)"></app-period-type>
  </div>
</div>

<div>
  <i class="map-container info" *ngIf="mappingToggle && allMapping.length === 0">
    You do not have any mapping as of now. Please click the below <b>+ Add</b> button to
    create new mapping.
  </i>
</div>


<div *ngIf="allMapping.length" class="mapping-list">
  <mat-card *ngFor="let mapObj of allMapping; index as i" class="mapping-card">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="row" fxLayout.lt-sm="column" class="map-list" fxLayoutAlign="space-between center"
        fxLayoutAlign.lt-sm="">
        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutAlign.lt-sm="">
          <div class="serial-number">
            {{ i + 1 }}.
          </div>

          <img *ngIf="appInfo?.logo?.url" [src]="appInfo?.logo?.url" [attr.height]="appInfo?.logo?.height || 20"
            class="logo-img" />
          <span class="mapping-name"
            [matTooltipDisabled]="!isSmallScreen || mapObj.appField?.name?.length <= 15 || mapObj.appField?.__id?.length <= 10"
            [matTooltip]="mapObj.appField?.name">
            {{ isSmallScreen ? (mapObj.appField?.name || mapObj.appField?.__id | truncate: [15]) :
            (mapObj.appField?.name || mapObj.appField?.__id) }}
            <span *ngIf="mapObj.appField?.required" class="warn" matTooltip="Required">*</span>
          </span>
        </div>

        <div class="mapping-icon">
          <mat-icon color="primary">sync_alt</mat-icon>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutAlign.lt-sm="">
          <div fxFlex="16px" fxHide fxShow.lt-sm></div>

          <img *ngIf="sourceInfo?.logo?.url" [src]="sourceInfo?.logo?.url"
            [attr.height]="sourceInfo?.logo?.height || 20" class="logo-img" />

          <span class="mapping-name"
            [matTooltipDisabled]="mapObj?.mappingType === 'templateField' || !isSmallScreen || mapObj.sourceField?.name?.length <= 15"
            [matTooltip]="mapObj.sourceField?.name">
            {{ isSmallScreen 
              ? (showMappingType(mapObj)?.length > 15 ? (showMappingType(mapObj) | slice:0:15) + '...' : showMappingType(mapObj))
              : showMappingType(mapObj) }}
          </span>

          <mat-icon class="mapping-type-icon">
            {{ mapObj.mappingType === 'templateField' ? 'description' : (mapObj.appField?.dataType === 'array' ?
            'view_list' : 'text_fields') }}
          </mat-icon>

        </div>

        <div *ngIf="mapObj.sourceField?.referenceField && !mapObj.sourceField?.referenceField?.hidden">
          <div class="mapping-icon">
            <mat-icon color="primary">east</mat-icon>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <mat-icon>text_fields</mat-icon>
            <span [matTooltipDisabled]="!isSmallScreen || mapObj.sourceField?.referenceField?.name?.length <= 15"
              [matTooltip]="mapObj.sourceField?.referenceField?.name">
              {{ mapObj.sourceField?.referenceField?.name }}
            </span>
            <img *ngIf="sourceInfo?.logo?.url" [src]="sourceInfo?.logo?.url"
              [attr.height]="sourceInfo?.logo?.height || 20" class="logo-img" />
          </div>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="end center" class="action-icons">
        <span matTooltip="Edit this mapping" (click)="mappingToggle = !mappingToggle; editMappingField(mapObj, i)"
          class="icon-container">
          <mat-icon class="icon-edit">edit</mat-icon>
        </span>
        <span matTooltip="Delete this mapping" [ngClass]="{ 'disabled': mapObj.appField.required || mapObj.sourceField.foreignKey }"
          (click)="!mapObj.appField.required && deleteMappingField(i)" class="icon-container">
          <mat-icon class="icon-delete">delete</mat-icon>
        </span>
      </div>
    </div>
  </mat-card>
  <mat-divider></mat-divider>
</div>


<div fxLayout="row" fxLayoutAlign="start center">

  <button *ngIf="mappingToggle" class="create-button-container" matTooltip="Click to add mapping." mat-raised-button
    color="primary" (click)="mappingToggle = !mappingToggle;onNewMapping()">
    <mat-icon>add_circle</mat-icon> Add
  </button>

  <button *ngIf="mappingToggle" class="map-add-plus-btn" matTooltip="Automatic mapping" mat-icon-button color="primary"
    (click)="checkForAutoMapping('manual')">
    <mat-icon>wifi_protected_setup</mat-icon>
  </button>
</div>

<mat-card appearance="outlined" *ngIf="!mappingToggle" class="mapping-config-card">
  <mat-card-content class="mapping-config-card-content">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <mat-form-field class="form-field" fxFlex="95" fxFlex.lt-sm="100">
        @if (appInfo?.label) {
          <mat-label>{{ appInfo.label }}</mat-label>
        } @else {
          <mat-label>{{ appInfo?.app || 'App' }} {{appInfo?.object?.name || appInfo?.action}} field</mat-label>
        }
        <mat-icon style="vertical-align: middle" *ngIf="selectedAppField.value"
          [matTooltip]="selectedAppField.value.dataType" matPrefix>
          {{ appFieldIconMap[selectedAppField.value.dataType] }}
        </mat-icon>
        <img matSuffix [matTooltip]="appInfo?.app" *ngIf="selectedAppField.value" class="box-img"
          [src]="appInfo?.logo?.url" [attr.height]="appInfo?.logo?.height || 20"
          alt="" />
        <input type="text" class="input-row" aria-label="AppField" #appFieldInput matInput
          matAutocompletePosition="below" [formControl]="selectedAppField" [matAutocomplete]="autoAppField" />

        @if(selectedAppField?.value?.description) {
          <mat-hint class="info"><strong>Hint: </strong>{{ selectedAppField.value.description }}</mat-hint>
        }

        <button matSuffix mat-icon-button aria-label="Clear" (click)="clearAppField()" class="clear-icon-button">
          <mat-icon>close</mat-icon>
        </button>

        <mat-autocomplete autoActiveFirstOption class="autocomplete" #autoAppField="matAutocomplete"
          [displayWith]="displayFnForAppField.bind(this)" (optionSelected)="onSelectionOfAttr($event.option.value)">
          <mat-option class="autocomplete-option">-- Reset Selection --</mat-option>
          <mat-option class="autocomplete-option" *ngFor="let attr of filteredObjectAttributes | async" [value]="attr">
            <mat-icon class="option-icon" [matTooltip]="attr.dataType">{{ appFieldIconMap[attr.dataType] }}</mat-icon>
            <span class="option-text">{{ attr.name || attr.__id }} <i class="info">({{ attr.dataType }})</i></span>
            <img *ngIf="appInfo?.logo?.url" class="box-img"
              [src]="appInfo?.logo?.url" [attr.height]="appInfo?.logo?.height || 20"
              alt="" />
          </mat-option>
          <mat-option *ngIf="isSupportsCustomAttribute" value="AW_CREATE_NEW_ATTRIBUTE">-- Create New --</mat-option>
        </mat-autocomplete>

      </mat-form-field>
      <!-- <button  *ngIf="selectedAppField.value"fxFlex="5" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Drill Down" matTooltip="Looking for more fields to map?"> -->
      <div *ngIf="!suppressDrillDown && selectedAppField.value" fxFlex="5"> <mat-icon class="picker-icon" [matMenuTriggerFor]="menu"
          aria-label="Drill Down" matTooltip="Looking for more fields to map?">more_vert</mat-icon> </div>
      <!-- </button> -->
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="openMappingDrillDownDialog()">Drill down</button>
        <button *ngIf="isSupportsCustomAttribute" mat-menu-item (click)="openMappingAddAttrDialog()">+ Add Attribute</button>
      </mat-menu>
    </div>
    <ng-container *ngIf="isLineItemMapping; else mappingTemplate">
      <mat-card>
        <mat-card-content>
          <div fxLayout="column" fxLayoutGap="10px">
            <mat-card appearance="outlined" *ngFor="let itemMapObj of itemsMappingArr[selectedAppFieldId]; index as i">
              <mat-card-content>
                <div fxLayout="column" fxLayoutGap="5px">
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <span><strong>Item Mapping</strong></span>
                    <!-- <button mat-icon-button color="warn" (click)="removeItem(i)" matTooltip="Remove Item">
                  <mat-icon>delete</mat-icon>
                </button> -->
                  </div>
                  <app-mapping-setup (mappingChange)="setMappingArr($event, i)" [mappingArr]="itemMapObj"
                    [serviceType]="serviceType" [appInfo]="appInfo" [sourceInfo]="sourceInfo"
                    [appFields]="getItemAppFields()" [sourceFields]="sourceFields">
                  </app-mapping-setup>
                </div>
              </mat-card-content>
            </mat-card>
            <!-- <button mat-stroked-button color="primary" (click)="addItem()" style="width: fit-content;">+ Add Item</button> -->
          </div>
        </mat-card-content>
      </mat-card>

    </ng-container>

    <div class="map-actions-btn">
      <button mat-raised-button color="primary" (click)="addMappingField()">
        {{ isEditEnabled ? "Update" : "Add" }}
      </button>
      <button style="margin-left: 5px" mat-stroked-button color="primary" (click)="resetMappingSettings()">
        Cancel
      </button>
    </div>

    <ng-template #mappingTemplate>
      <div fxLayout="row">
        <p><b>Choose the mapping type.</b></p>
        <a *ngIf="displayOption?.showHelpLinks" mat-icon-button target="_blank" href="https://appiworks.jivrus.com"
          [matTooltip]="'Know about mapping'" aria-label="Help" rel="noreferrer">
          <mat-icon>help</mat-icon>
        </a>
      </div>
      <div *ngIf="selectedAppField.value" style="font-size: smaller;"> How do you want to compose value for
        {{selectedAppField.value.name || selectedAppField.value.__id}}? </div>
      <mat-radio-group color="primary" aria-label="Select an option" [formControl]="selectedMappingType"
        (change)="radioChange($event)" class="radio-group">
        <mat-radio-button value="sourceField">{{ clientPlatformService.sourceApp || "Source" }} Field</mat-radio-button>
        <mat-radio-button value="templateField">Templated</mat-radio-button>
        <mat-radio-button value="conditional">Conditional</mat-radio-button>
      </mat-radio-group>

      <!-- <p class="margin-top-small" *ngIf="selectedMappingType.value === 'sourceField'">
      <b>Map to a {{ clientPlatformService.sourceApp || "Source" }} Field.</b>
      <a *ngIf="displayOption?.showHelpLinks" mat-icon-button target="_blank" href="https://appiworks.jivrus.com" [matTooltip]="'Know about field mapping'" aria-label="Help"
        rel="noreferrer">
      <mat-icon>help</mat-icon>
    </a>
    </p> -->
      <!-- <p class="margin-top-small" *ngIf="selectedMappingType.value === 'templateField'">
      <b>Prepare value from Template.</b>
      <a *ngIf="displayOption?.showHelpLinks" mat-icon-button target="_blank" href="https://appiworks.jivrus.com" [matTooltip]="'Know about template field mapping'" aria-label="Help"
      rel="noreferrer">
    <mat-icon>help</mat-icon>
  </a>
    </p> -->

      <div fxLayout="row" class="margin-top-small" *ngIf="selectedMappingType.value === 'sourceField'">
        <p><b>Map to a {{ clientPlatformService.sourceApp || "Source" }} Field.</b></p>
        <a *ngIf="displayOption?.showHelpLinks" mat-icon-button target="_blank" href="https://appiworks.jivrus.com"
          [matTooltip]="'Know about field mapping'" aria-label="Help" rel="noreferrer">
          <mat-icon>help</mat-icon>
        </a>
      </div>

      <div fxLayout="row" class="margin-top-small" *ngIf="selectedMappingType.value === 'templateField'">
        <p><b>Prepare value from Template.</b></p>
        <a *ngIf="displayOption?.showHelpLinks" mat-icon-button target="_blank" href="https://appiworks.jivrus.com"
          [matTooltip]="'Know about template field mapping'" aria-label="Help" rel="noreferrer">
          <mat-icon>help</mat-icon>
        </a>
      </div>

      <div fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="space-between center" *ngIf="selectedMappingType.value === 'sourceField'">
          <mat-form-field class="form-field" fxFlex="95" fxFlex.lt-sm="90">
            @if (sourceInfo?.label) {
              <mat-label>{{ sourceInfo.label }}</mat-label>
            } @else {
              <mat-label>{{ clientPlatformService.sourceApp || sourceInfo?.app || "Source" }} {{ sourceInfo?.object || sourceInfo?.action }} field</mat-label>
            }
            <mat-icon style="vertical-align: middle;" *ngIf="selectedSourceField.value"
              [matTooltip]="selectedSourceField.value.dataType" matPrefix>
              {{ sourceFieldIconMap[selectedSourceField.value.dataType] }}
            </mat-icon>
            <img matSuffix [matTooltip]="sourceInfo?.app" *ngIf="selectedSourceField.value"
              class="box-img" [src]="sourceInfo?.logo?.url"
              [attr.height]="sourceInfo?.logo?.height || 20" alt="" />
            <input type="text" class="input-row" aria-label="SourceField" matInput matAutocompletePosition="below"
              [formControl]="selectedSourceField" [matAutocomplete]="autoSourceField" />
            <button matSuffix mat-icon-button aria-label="Clear" (click)="clearSourceField()" class="clear-icon-button" [disabled]="selectedSourceField.value?.foreignKey">
              <mat-icon>close</mat-icon>
            </button>
            @if(selectedSourceField?.value?.description) {
              <mat-hint class="info"><strong>Hint: </strong>{{ selectedSourceField.value.description }}</mat-hint>
            }
            <mat-autocomplete autoActiveFirstOption #autoSourceField="matAutocomplete"
              [displayWith]="displayFnForSourceField" (optionSelected)="checkForReferenceMapping($event.option.value)"
              class="autocomplete">
              <mat-option *ngFor="let sourceAttr of filteredSourceAttributes | async" [value]="sourceAttr"
                class="autocomplete-option">
                <div class="option-content" fxLayout="row" fxLayoutAlign="center center">
                  <mat-icon class="option-icon" [matTooltip]="sourceAttr.dataType">{{ sourceFieldIconMap[sourceAttr.dataType] }}</mat-icon>
                  <span class="option-text"> {{ sourceAttr.name }} <i class="info">({{ sourceAttr.dataType }})</i></span>
                  <img *ngif="sourceInfo?.logo?.url" class="box-img"
                    [src]="sourceInfo?.logo?.url"
                    [attr.height]="sourceInfo?.logo?.height || 20" alt="" />
                </div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <div *ngIf="showFormFieldsRefreshButton" fxFlex="10" fxLayoutAlign="start center">
            <div class="icon-spinner-container" fxFlex="none">
              <mat-spinner *ngIf="spinner" color="warn" diameter="15" class="responsive-spinner">
              </mat-spinner>
              <mat-icon *ngIf="!spinner" class="picker-icon" matTooltip="Refresh Form Fields" aria-label="Refresh"
                (click)="refreshFormFields()">
                refresh
              </mat-icon>
            </div>
          </div>

          <div *ngIf="!suppressDrillDown" fxFlex="5">
            <mat-icon class="picker-icon" [matMenuTriggerFor]="srcMenu" aria-label="Drill Down"
              matTooltip="Looking for more fields to map?">more_vert</mat-icon>
          </div>

          <mat-menu #srcMenu="matMenu">
            <button mat-menu-item (click)="openSourceMappingDrillDownDialog()">Drill down</button>
          </mat-menu>
        </div>

        <div fxLayout="column">
          <div fxLayout="row" *ngIf="selectedSourceField.value?.reference && referenceFields.length">
            <mat-icon fxFlex="2">subdirectory_arrow_right</mat-icon>
            <mat-form-field fxFlex="95.3">
              <mat-label>Reference field from {{ clientPlatformService.sourceApp || sourceInfo?.app }} {{
                selectedSourceField.value.reference }}</mat-label>
              <mat-icon style="vertical-align: middle;" [matTooltip]="selectedReferenceField.value.dataType" matPrefix>
                {{ sourceFieldIconMap[selectedReferenceField.value.dataType] }}
              </mat-icon>
              <img matSuffix [matTooltip]="sourceInfo?.app" *ngIf="selectedSourceField.value"
                class="box-img" [src]="sourceInfo?.logo?.url"
                [attr.height]="sourceInfo?.logo?.height || 20" alt="" />
              <input type="text" aria-label="ReferenceField" matInput matAutocompletePosition="below"
                [formControl]="selectedReferenceField" [matAutocomplete]="autoReferenceField" />
              <button matSuffix mat-icon-button aria-label="Clear" (click)="clearReferenceField()">
                <mat-icon>close</mat-icon>
              </button>

              <mat-autocomplete autoActiveFirstOption #autoReferenceField="matAutocomplete"
                [displayWith]="displayFnForSourceField">
                <mat-option *ngFor="let refAttr of filteredReferenceAttributes | async" [value]="refAttr">
                  <mat-icon [matTooltip]="refAttr.dataType">{{ sourceFieldIconMap[refAttr.dataType] }}</mat-icon>
                  {{ refAttr.name }} <i class="info">({{ refAttr.dataType }})</i>
                  <img *ngif="sourceInfo?.logo?.url" class="box-img"
                    [src]="sourceInfo?.logo?.url"
                    [attr.height]="sourceInfo?.logo?.height || 20" alt="" />
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <div fxFlex="1.7"></div>
          </div>
        </div>

        @if(selectedMappingType.value === 'sourceField' && selectedSourceField?.value?.dataType === "array" && selectedSourceField?.value?.semanticType === "lineItem") {
          <div fxLayout="column" fxLayoutGap="8px">
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-slide-toggle
                color="primary"
                matTooltip="Export this into a different {{appInfo?.options?.appObjectDisplayName}}"
                class="activate-slide-toggle"
                [(ngModel)]="isChildObjectMapping"
                (change)="initChildObjectMapping()"
              >
              </mat-slide-toggle>
              <span>Export this into a different {{appInfo?.options?.appObjectDisplayName}}</span>
            </div>
            <br>
            @if (isChildObjectMapping) {
              <app-select-box-object
                [isCustomObjectSupported]="appInfo?.options?.isCustomObjectSupported"
                [createObjectData]="childObjectNewObjectData"
                [boxId]="appInfo?.connection?.box_id"
                [connectionId]="appInfo?.connection?._id"
                [oldValue]="previousChildObjectId"
                (boxObjectInput)="onChildObjectSelected($event)"
                (boxObjectSelectionError)="exportObjectSelectionError($event)"
              >
              </app-select-box-object>
              @if(selectedChildObject && !isChildObjectFieldsFetched) {
                <mat-spinner [diameter]="20"></mat-spinner>
              }
              @if(selectedChildObject && isChildObjectFieldsFetched) {
                <mat-card appearance="outlined">
                  <mat-card-header>
                    <mat-card-title> Mapping for {{ selectedSourceField.value.name }} </mat-card-title>
                    <mat-card-subtitle>Using this mapping you can map {{ this.childObjectAppInfo.label }}s and {{ this.childObjectSourceInfo.label }}s.</mat-card-subtitle>
                   
                  </mat-card-header>
                  
                  <mat-card-content style="margin-top: 20px;">
                    <app-mapping-setup
                      (mappingChange)="setChildObjectMapping($event)"
                      [mappingArr]="childObjectMappings"
                      [serviceType]="serviceType"
                      [appInfo]="childObjectAppInfo"
                      [sourceInfo]="childObjectSourceInfo"
                      [appFields]="childObjectFields"
                      [sourceFields]="childObjectLineItemFieldsMap[selectedSourceFieldId]"
                    >
                    </app-mapping-setup>
                  </mat-card-content>
                </mat-card>
              }
            }
          </div>
        }

        <div *ngIf="selectedMappingType.value === 'templateField'" fxLayout="row" fxLayoutAlign="space-between center">
          <mat-form-field fxFlex>
            <mat-label>Template Message</mat-label>
            <div style="display: flex; flex-direction: row">
              <textarea matInput placeholder="e.g. Hi ${Name}" [formControl]="templatedValue"></textarea>
            </div>
          </mat-form-field>
          <app-field-picker fxFlex="15" [inputFieldMap]="fieldMap"
            (selectedFields)="insertCursor($event)">
          </app-field-picker>
        </div>

        <!-- , loadedAttributes: loadedAttributes -->
        <div *ngIf="selectedMappingType.value === 'conditional'" class="conditional-layout">
          <div class="margin-top-small" fxLayout="row">
            <p class="header-text"><b>Conditional.</b></p>
            <a *ngIf="displayOption?.showHelpLinks" mat-icon-button target="_blank" href="https://appiworks.jivrus.com"
              [matTooltip]="'Know about conditional mapping'" aria-label="Help" rel="noreferrer">
              <mat-icon>help</mat-icon>
            </a>
          </div>
          <!-- <mat-label>Conditional</mat-label> -->
          <div *ngFor="let conditionObj of conditionMap.conditionSets; index as i">
            <mat-card class="condition-card">
              <mat-card-content>
                <div fxLayout="row">
                  <div fxFlex="90" class="condition-text"> <b>Condition Set {{i + 1}}</b> </div>
                  <mat-icon fxFlex="10" color="warn" (click)="deleteCondition(i)">delete</mat-icon>
                </div>

                <div>
                  <!-- <app-filter-config
                  [filter]="{filterEnabled: true, filterItems: conditionObj.conditions }"
                  [fieldListingMap]="{sourceFields: sourceFields, systemFields: systemFields, oldAttributes: loadedAttributes}"
                  [boxObjectAttributes]="appFields"
                  [hideToggle]="true"
                  [showAttributePicker]="true"
                  [lockFilterType]="true"
                  (selectedFilter)="filterChanged($event, conditionObj)"
                >
                </app-filter-config> -->
                  <app-filter-component [filter]="{filterEnabled: true, filterItems: conditionObj.conditions }"
                    [fieldListingMap]="{sourceFields: sourceFields, systemFields: systemFields, oldAttributes: loadedAttributes}"
                    [boxObjectAttributes]="appFields" [hideToggle]="true" [showAttributePicker]="true"
                    [lockFilterType]="true"
                    (selectedFilter)="filterChanged($event, conditionObj)"></app-filter-component>
                </div>
                <div fxLayout="row">
                  <mat-form-field class="form-field" class="noBottomMargin" fxFlex="90" fxFlex.lt-sm="85">
                    <mat-label>Value</mat-label>
                    <input matInput #filterValueInput [(ngModel)]="conditionObj.resultField">
                  </mat-form-field>
                  <app-field-picker [inputFieldMap]="fieldMap" fxFlex="10" fxFlex.lt-sm="15"
                    (selectedFields)="insertCursorDefault($event, conditionObj)"></app-field-picker>
                </div>
              </mat-card-content>

            </mat-card>
          </div>
          <div>
            <div style="height: 1rem"></div>
            <button mat-stroked-button color="primary" (click)="addCondition()">
              <mat-icon>add</mat-icon> Add Condition
            </button>
          </div>
          <div fxLayout="row">
            <mat-form-field class="form-field" class="noBottomMargin" fxFlex="90" fxFlex.lt-sm="85">
              <mat-label>Value</mat-label>
              <input matInput #filterValueInput [(ngModel)]="conditionMap.defaultField">
            </mat-form-field>
            <app-field-picker [inputFieldMap]="fieldMap" fxFlex="10" fxFlex.lt-sm="15"
              (selectedFields)="insertCursorDefaultField($event, conditionMap)"></app-field-picker>
          </div>
        </div>

      </div>
    </ng-template>
  </mat-card-content>
</mat-card>
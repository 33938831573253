<div
  *ngIf="!textEditMode && builderMode"
  class="outerCover"
  [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'"
  fxLayout fxLayoutAlign="flex-start center"
  [class.selectedNow]="selectedWidgetId == widgetMeta.id"
  [class.hoveredNow]="selectedWidgetId == widgetMeta.id? false: hoveredNow"
  (mouseenter)="widgetMouseenter()"
  (mouseleave)="widgetMouseleave()"
  (click)="onClick($event)"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
>
  <!-- [matMenuTriggerFor]="menu"
  #menuTrigger="matMenuTrigger" -->
  <widget-drag-handle [class.invisible]="(selectedWidgetId !== widgetMeta.id && !hoveredNow)"></widget-drag-handle>
  <div class="innerCover" (click)="onClick($event)" (mousedown)="$event.stopPropagation()">

    <inline-title [widgetMeta]="widgetMeta" [builderMode]="builderMode" [styles]="styles" (widgetTitleChanged)="newWidgetMeta.emit(widgetMeta)"></inline-title>
    <div style="height: .5rem;"></div>

    <div
      style="display: flex; flex-wrap: wrap;"
      [style.gap]="widgetMeta.config.orientation.value === 'vertical' ? '.5rem' : '1rem'"
      [style.flex-direction]="widgetMeta.config.orientation.value === 'vertical' ? 'column' : 'row'"
      [style.justify-content]="widgetMeta.config.orientation.value === 'horizontal' ? widgetMeta?.config?.alignment?.value : 'center'"
      [style.align-items]="widgetMeta.config.orientation.value === 'vertical' ? widgetMeta?.config?.alignment?.value : 'center'"
    >
      <ng-container *ngFor="let option of availableOptions; let index = index">
        <mat-checkbox
          color="primary"
          class="checkListItem"
          [(checked)]="option.selected"
          (change)="checkboxChange($event.checked, option, index)"
        >
          {{ option.name }}
        </mat-checkbox>
        <br *ngIf="widgetMeta.config.orientation.value === 'vertical'">          <!-- we can conditionally show this br tag based on orientation, vertical/horizontal-->
      </ng-container>
    </div>
    <!-- <div style="display: flex; flex-wrap: wrap;" [style.justify-content]="widgetMeta?.config?.alignment?.value">
    </div> -->

    <div style="height: 1rem;"></div>

    <!-- and stopPropagation so that mat-menu doesnt fire -->
    <ng-container *ngIf="!inputVisible; else inputVisibleBlock">
      <div class="newOptionBlock">
        <button mat-stroked-button class="addItemButton" (click)="inputVisible = true; $event.stopPropagation()">
          <mat-icon>add</mat-icon>
          Add New Option
        </button>
      </div>
    </ng-container>
    <ng-template #inputVisibleBlock>
      <div  fxLayout="row wrap" fxLayoutAlign="flex-start center" fxLayoutGap=".5rem">
        <div fxFlex="75">
          <mat-form-field class="newOptionBlock" (click)="$event.stopPropagation()" fxFlexFill>
            <mat-label>Add New Option</mat-label>
            <input #newItemText matInput type="text" placeholder="New option...">
          </mat-form-field>
        </div>
        <mat-icon matSuffix class="icon" (click)="$event.stopPropagation(); inputVisible = false;">close</mat-icon>
        <mat-icon matSuffix class="icon" (click)="addItem(newItemText); $event.stopPropagation(); inputVisible = false;">check</mat-icon>
      </div>
    </ng-template>
  </div>
</div>


<div
  *ngIf="!builderMode"
  class="outerCover"
  fxLayout
  [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'"
  (click)="onClick($event)"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip?.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip?.enabled"
>
  <div
    class="innerCover"
    (click)="onClick($event)"
    (mousedown)="$event.stopPropagation()"
  >
    <inline-title [widgetMeta]="widgetMeta" [builderMode]="builderMode" [styles]="styles"></inline-title>
    <div style="height: .5rem;"></div>
    <!-- <div>{{ "viewOnly :" + widgetMeta.config.viewOnly?.value }}</div> -->
    <div
      style="display: flex; gap: 1rem; flex-wrap: wrap;"
      [style.flex-direction]="widgetMeta.config.orientation.value === 'vertical' ? 'column' : 'row'"
      [style.justify-content]="widgetMeta.config.orientation.value === 'horizontal' ? widgetMeta?.config?.alignment?.value : 'center'"
      [style.align-items]="widgetMeta.config.orientation.value === 'vertical' ? widgetMeta?.config?.alignment?.value : 'center'"
    >
      <ng-container *ngFor="let option of availableOptions; let index = index">
        <mat-checkbox
          color="primary"
          class="checkListItem"
          [disabled]="widgetMeta.config.viewOnly?.value"
          [(checked)]="option.selected"
          (change)="checkboxChange($event.checked, option, index)"
        >
          {{ option.name }}
        </mat-checkbox>
        <br *ngIf="widgetMeta.config.orientation.value === 'vertical'">
      </ng-container>
    </div>
    <div *ngIf="isTouched && !isValid" class="error-message">
      This field is required
    </div>
  </div>
</div>

<div
  *ngIf="builderMode"
  class="outerCover"
  [class.selectedNow]="selectedWidgetId == widgetMeta.id"
  [class.hoveredNow]="selectedWidgetId == widgetMeta.id ? false : hoveredNow"
  (mouseenter)="widgetMouseenter(); customMouseEnter()"
  (mouseleave)="widgetMouseleave()"
  (click)="onClick()"
>
  <widget-drag-handle [class.invisible]="selectedWidgetId !== widgetMeta.id && !hoveredNow"></widget-drag-handle>
  <div class="innerCover">
    <div
      [ngStyle]="{
        'width': (widgetMeta.config.width?.value ? widgetMeta.config.width.value + '%' : '100%'),
        'border-bottom': (widgetMeta.config.weight?.value ? widgetMeta.config.weight.value + 'px' : '1px') + ' solid ' + (widgetMeta.config.color?.value || 'darkgrey')
      }"
    ></div>
  </div>
</div>

<div *ngIf="!builderMode && (!widgetMeta.config.hidden || !widgetMeta.config.hidden.value)" class="outerCover" (click)="onClick()">
  <div class="innerCover">
    <div
      [ngStyle]="{
        'width': (widgetMeta.config.width?.value ? widgetMeta.config.width.value + '%' : '100%'),
        'border-bottom': (widgetMeta.config.weight?.value ? widgetMeta.config.weight.value + 'px' : '1px') + ' solid ' + (widgetMeta.config.color?.value || 'darkgrey')
      }"
    ></div>
  </div>
</div>

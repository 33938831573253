import { Component, OnInit, ViewChild, OnChanges, SimpleChanges, DoCheck } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Subject } from 'rxjs';
import { MetaService } from 'src/app/bloom/services/meta-service';

import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { ResourcePermissionService } from 'src/app/shared/services/resource-permission.service';
import { PageService } from 'src/app/bloom/services/page-service.service';

const PERIOD_TYPES = [
  {value: 'date', name: 'Date'},
  {value: 'datetime', name: 'Date Time'},
  {value: 'time', name: 'Time'}
]

@Component({
  selector: 'app-period',
  templateUrl: './period.component.html',
  styleUrls: ['./period.component.css']
})
export class PeriodComponent extends BaseWidgetComponent implements OnInit, OnChanges, DoCheck {
  contextMenuActions: {};
  hoveredNow: boolean = false;
  oldMeta: any;
  private destroy: any = new Subject();

  get periodTypes() {
    return PERIOD_TYPES;
  }

  @ViewChild('menuTrigger') periodMenuTrigger: MatMenuTrigger;

  constructor(
    public metaService: MetaService,
    public pageService: PageService,
    public resourcePermissionService: ResourcePermissionService
  ) {
    super(metaService, pageService, resourcePermissionService)
    console.log("in period constructor", this.widgetMeta)
  }

  ngOnInit(): void {

    super.ngOnInit()

    console.log("in period onInit", this.widgetMeta)
    this.destroy = this.metaService.$contextChanged.subscribe((contextActions: any) => {
      if(contextActions && this.widgetMeta.id == contextActions?.widgetId){
        this.action(contextActions)
      }
    })
  }

  ngOnDestroy(): void {
    this.destroy.unsubscribe();
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedWidgetId && (changes.selectedWidgetId.currentValue !== this.widgetMeta.id)) {
      if (this.periodMenuTrigger && this.periodMenuTrigger.menuOpen) {
        this.periodMenuTrigger.closeMenu()
      }
    }
    if(changes.contextActions?.currentValue){
      this.action(changes.contextActions.currentValue)
    }
    if(changes.widgetMeta?.currentValue){
      this.setContextActions()
    }
  }

  ngDoCheck(): void {
    if((!this.oldMeta && this.widgetMeta) || (JSON.stringify(this.oldMeta) != JSON.stringify(this.widgetMeta))){
      this.oldMeta = JSON.parse(JSON.stringify(this.widgetMeta))
    }
  }

  //----------------------------------- FUNCTIONS ---------------------------------------
  setPeriodType(type: string) {
    this.widgetMeta.config.value.periodType = type;
    this.widgetMeta.config.defaultValue.periodType = type;
  }

  setContextActions(){
    this.contextMenuActions = {
      actions: [
        "appearance",
        "bold",
        "underline",
        "color",
        "backgroundColor",
        "italic",
        "fontSize",
        "fontFamily",
        "edit",
      ],
      appearance: {
        value: this.widgetMeta?.config?.appearance?.value,
        type: this.widgetMeta?.config?.appearance?.type
      },
    }
    this.raiseContextMenuActions.emit(this.contextMenuActions)
  }

  action(event: any) {
    console.log("action received", event)
    if (event.actionType == "delete") {
      this.onDelete()
    }
    if (event.actionType == "updateStyles") {
      if (event?.data) {
        this.widgetMeta = JSON.parse(JSON.stringify(event.data));
        this.newWidgetMeta.emit(this.widgetMeta)
        super.generateStyles();
      }
    }
  }

  onClick() {
    if (!this.builderMode) {
      return;
    }

    console.log("date widget clicked in widget")
    this.widgetSelection.emit(this.widgetMeta.id)
  }

  onDelete() {
    console.log("widget ID", this.widgetMeta.id, "will be deleted")
    this.widgetDeletion.emit(this.widgetMeta.id)
    this.periodMenuTrigger?.closeMenu();
  }

  inputDetected(event: any) {
    // console.log("period change detected", event)
    const periodInput: any = {
      dataBindConfig: this.widgetMeta?.dataBindConfig,
      widgetId: this.widgetMeta.id
    }
    const rangeValue = JSON.parse(JSON.stringify(event));
    console.log('PERIOD TYPE', this.widgetMeta.config.value.periodType, rangeValue)
    this.widgetMeta.config.value.value = rangeValue; 
    periodInput.value = rangeValue;

    this.userInputReceived.emit(periodInput);
  }
}

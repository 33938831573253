import { Component, DoCheck, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DateService } from '../../date/date.service';

@Component({
  selector: 'app-period-type',
  templateUrl: './period-type.component.html',
  styleUrl: './period-type.component.css'
})
export class PeriodTypeComponent implements OnInit, DoCheck {
  @Input() widgetMeta;
  @Output() rangeValue = new EventEmitter<any>();

  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  oldMeta: any;

  get appearance() {
    return this.widgetMeta.config?.appearance?.value || 'standard'
  }

  get isRequired() {
    return this.widgetMeta?.config?.required?.value || false;
  }

  constructor(private dateService: DateService) {}

  ngOnInit(): void {
    this.setFormattedDate();
  }

  ngDoCheck(): void {
    if((!this.oldMeta && this.widgetMeta) || (JSON.stringify(this.oldMeta) != JSON.stringify(this.widgetMeta))){
      this.oldMeta = JSON.parse(JSON.stringify(this.widgetMeta))
    }
  }

  setFormattedDate() {
    if ((this.widgetMeta.config.value.value == null || this.widgetMeta.config.value.value == '') && this.widgetMeta.config.defaultValue.value) {
      this.widgetMeta.config.value.value = JSON.parse(JSON.stringify(this.widgetMeta.config.defaultValue.value));
    }
    const formattedValue = this.dateService.getFormattedDateTimeValue(this.widgetMeta.config.value.value, this.widgetMeta.config.value.periodType); 
    console.log('FORMATTED VALUE', formattedValue)
    this.range.setValue(formattedValue)
  }

  rangeInputDetected() {
    let rangeValue = null;
    const periodType = this.widgetMeta.config.value.periodType; 
    const clonedRangeValue = JSON.parse(JSON.stringify(this.range.value));

    switch (periodType) {
      case 'date':
      case 'datetime':
        Object.keys(clonedRangeValue).forEach((key) => {
          clonedRangeValue[key] = `${new Date(clonedRangeValue[key]).toISOString()}|date`
        });
        rangeValue = clonedRangeValue;
        break;
      case 'time':
        rangeValue = JSON.parse(JSON.stringify(this.widgetMeta.config.value.value));
        break;
      default:
        break;
    }

    this.rangeValue.emit(rangeValue);
  }
}

<div *ngIf="widgetExpressionConfig" fxLayout="row">

    <span > 
      <mat-checkbox  class="example-margin"  [(ngModel)]="widgetExpressionConfig.onEdit">
        On edit
      </mat-checkbox>
    </span>
    &nbsp;&nbsp;
    <span >
      <mat-checkbox  class="example-margin"
      [(ngModel)]="widgetExpressionConfig.onCreate">
        On create
      </mat-checkbox>
    </span>

    </div>

<div class="wrapper">
  <mat-form-field style="width: 80%;">
    <mat-icon color="primary" matPrefix>functions</mat-icon>
    <mat-label>
      <span>&nbsp;Choose Value</span>
    </mat-label>
    <input
      matInput
      [formControl]="expListingACForm"
      [matAutocomplete]="auto"
    />
    <button *ngIf="expListingACForm.value" matSuffix mat-icon-button aria-label="Clear"
      (click)="clearSelection()">
      <mat-icon>close</mat-icon>
    </button>

    <mat-autocomplete
      #auto="matAutocomplete"
      [displayWith]="displayFunction"
      (optionSelected)="expressionSelectionHandler($event.option.value)"
    >
      <mat-option *ngFor="let exp of filteredExpressions | async" [value]="exp">
        {{ exp.name }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <!-- <div class="expConfig" *ngIf="isExpressionSelected">
    <app-expression-configuration
      [selectedExpression]="selectedExpression"
      [widgetExpressionConfig]="widgetExpressionConfig"
      (raiseExpressionConfig)="expressionConfigReceived($event)"
    >
    </app-expression-configuration> -->

    <!-- <div [ngSwitch]="selectedExpression.id">
      <div *ngSwitchCase="'__add__'">
        <app-add-exp-config
          [selectedExp]="selectedExpression"
          [widgetExpressionConfig]="widgetExpressionConfig"
          (raiseExpressionConfig)="expressionConfigReceived($event)"
        >
        </app-add-exp-config>
      </div>
      <div *ngSwitchCase="'__currentdatetime__'">
        <app-currentdatetime-exp-config
          [selectedExp]="selectedExpression"
          [widgetExpressionConfig]="widgetExpressionConfig"
          (raiseExpressionConfig)="expressionConfigReceived($event)"
        >
        </app-currentdatetime-exp-config>
      </div>
      <div *ngSwitchCase="'__bloomowneremail__'">
      </div>
      <div *ngSwitchDefault>

      </div>
    </div> -->
  <!-- </div> -->
</div>

import { Widget } from "./baseWidget";
import { TextFormatting } from "./textFormat";

export class Time extends Widget {
  type = 'time';
  gridX = 6;
  minGridX = 3;
  config = {
    props: [
      "placeholder",
      'tooltip',
      'viewOnly',
      // 'customOutputFormat',
      'appearance',
    ],
    appearance: {
      displayName: "Input appearance",
      value: "standard",
      type: "select"
    },
    placeholder: {
      displayName: "Time Placeholder",
      value: "Enter time"
    },
    tooltip: {
      displayName: 'Tooltip',
      value: "",
      type: 'text',
      enabled: true
    },
    customOutputFormat: {
      displayName: "Custom output time format",
      // value: 'h:mm a',
      type: 'date-time-format-input',
      enabled: false,
      customTime: true,
      customTimeFormat: 'h:mm a'
    },
    viewOnly: {
      displayName: 'View Only Mode',
      description: 'When turned on, user can not change the value',
      value: false,
      type: 'boolean',
      resetValue: false
    }
  }

  //text formatting properties
  textFormat;

  constructor(id: number, name: string) {
    super(id, name)
    this.attachCommonConfig(this)
    this.config['title'].value = "Choose time";
    this.config['value'].resetValue = '';
    this.config['defaultValue'].valueType = 'time';
    //initialize the styles with default values
    this.textFormat = TextFormatting.initStyles(JSON.parse(JSON.stringify(TextFormatting.textFormatting())))
  }

  createFormattedValue(rawDateTimeValue){
    if(!rawDateTimeValue) return '';
  }

  public getWidgetConfig(){
    let map = {
      valuePath: "config.value.value"
    }
    return map;
  }

  public getWidgetMeta() {
    return this
  }
}

import { Widget } from "./baseWidget";
import { TextFormatting } from "./textFormat";

export class Star extends Widget {
  type = 'star';
  gridX = 6
  minGridX = 6

  config = {
    props: [
      "alignment",
      "starCount",
      "fillColor",
      'tooltip',
      'viewOnly',
    ],
    alignment: {
      displayName: "Text Alignment",
      value: "flex-start",
      type: 'app-alignment-settings'
    },
    starCount: {
      displayName: "Star Count",
      type: 'number',
      value: 5
    },
    fillColor: {
      displayName: "Star Fill Color",
      value: '#333'
    },
    tooltip: {
      displayName: 'Tooltip',
      value: "",
      type: 'text',
      enabled: true
    },
    viewOnly: {
      displayName: 'View Only Mode',
      description: 'When turned on, user can only see the star rating',
      value: false,
      type: 'boolean',
      resetValue: false
    }
  };

  //text formatting properties
  textFormat;

  constructor(id: number, name: string) {
    super(id, name)
    this.attachCommonConfig(this)

    this.config['title'].value = "Give a rating";
    this.config['value'].value = 0;
    this.config['value'].type = 'number';
    this.config['defaultValue'].valueType = 'number';
    this.config['defaultValue'].value = 0;
    //initialize the styles with default values
    this.textFormat = TextFormatting.initStyles(JSON.parse(JSON.stringify(TextFormatting.textFormatting())))

    // add widget specific text formatting if any

  }

  public getWidgetConfig() {
    let map: any = {
      valuePath: "config.value.value",
      effect: {
        styles: []
      }
    }
    map = this.mergeEffectStyle(map);
    return map;
  }
}


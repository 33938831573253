import { Component, Input, OnInit, Output, EventEmitter, ViewChild, SimpleChanges, OnChanges } from '@angular/core';
import { FlexAlignStyleBuilder } from '@ngbracket/ngx-layout';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { Subject } from 'rxjs';
import { MetaService } from 'src/app/bloom/services/meta-service';

import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { PageService } from 'src/app/bloom/services/page-service.service';
import { ResourcePermissionService } from 'src/app/shared/services/resource-permission.service';


@Component({
  selector: 'app-duration',
  templateUrl: './duration.component.html',
  styleUrls: ['./duration.component.css']
})
export class DurationComponent extends BaseWidgetComponent implements OnInit, OnChanges {

  contextMenuActions: {};
  @Output() dateReceived: EventEmitter<any> = new EventEmitter();

  value = "";
  hoveredNow: boolean = false;
  private destroy:any = new Subject();
  @ViewChild('menuTrigger') durationTrigger: MatMenuTrigger

  constructor(
    public metaService: MetaService,
    public pageService: PageService,
    public resourcePermissionService: ResourcePermissionService
  ) {
    super(metaService, pageService, resourcePermissionService)
    console.log("in duration constructor", this.widgetMeta)
  }

  ngOnInit(): void {

    super.ngOnInit()
    console.log("in duration onInit", this.widgetMeta)
    this.destroy = this.metaService.$contextChanged.subscribe((contextActions: any) => {
      if(contextActions && this.widgetMeta.id == contextActions?.widgetId){
        this.action(contextActions)
      }
    })
    //setting default value if the value doesn't exist
    if (this.checkForEmptyValue() && this.widgetMeta.config.defaultValue.value) {
      this.widgetMeta.config.value.value = JSON.parse(JSON.stringify(this.widgetMeta.config.defaultValue.value));
    } 
  }

  ngOnDestroy(): void {
    this.destroy.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedWidgetId && (changes.selectedWidgetId.currentValue !== this.widgetMeta.id)) {
      if (this.durationTrigger && this.durationTrigger.menuOpen) {
        this.durationTrigger.closeMenu()
      }
    }
    if(changes.contextActions?.currentValue){
      this.action(changes.contextActions.currentValue)
    }
    if(changes.widgetMeta?.currentValue){
      this.setContextActions()
    }
  }



  //----------------------------------- FUNCTIONS ---------------------------------------

  checkForEmptyValue() {
    const value = this.widgetMeta.config.value.value;
    let isEmpty = true;

    if (typeof value === 'object') {
      const keys = Object.keys(value);
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (value[key] != 0) {
          isEmpty = false;
          break;
        }
      }
    }
    return isEmpty;
  }

  setContextActions(){
    this.contextMenuActions = {
      actions: [
        "bold",
        "underline",
        "color",
        "backgroundColor",
        "italic",
        "fontSize",
        "fontFamily",
        "edit"
      ],
    }
    this.raiseContextMenuActions.emit(this.contextMenuActions)
  }

  action(event: any) {
    console.log("action received", event)
    if (event.actionType == "delete") {
      this.onDelete()
    }
    if (event.actionType == "updateStyles") {
      if (event?.data) {
        this.widgetMeta = JSON.parse(JSON.stringify(event.data));
        this.newWidgetMeta.emit(this.widgetMeta)
        super.generateStyles();
      }
    }
  }

  onClick() {
    if (!this.builderMode) {
      return;
    }

    console.log("date widget clicked in widget")
    this.widgetSelection.emit(this.widgetMeta.id)
  }

  onDelete() {
    console.log("widget ID", this.widgetMeta.id, "will be deleted")
    this.widgetDeletion.emit(this.widgetMeta.id)
    this.durationTrigger.closeMenu();
  }

  inputDetected(event: any) {
    console.log("date change detected", event.value)
    let dateInput: any = {
      dataBindConfig: this.widgetMeta?.dataBindConfig,
      widgetId: this.widgetMeta.id,
      value: this.value
    }

    // if(this.value !== ''){
    //   this.dateReceived.emit(dateInput)
    // }
  }

}

import { display } from "html2canvas/dist/types/css/property-descriptors/display";
import { Widget } from "./baseWidget";
import { TextFormatting } from './textFormat'

export class NumberInput extends Widget {
  type = 'numberinput';
  gridX = 4;
  minGridX = 3;
  config = {
    props: [
      // 'showTitle',
      "placeholder",
      'value',
      'tooltip',
      'appearance',
      'viewOnly',
    ],
    placeholder: {
      displayName: "Placeholder text",
      value: "Input number",
      type: 'text'
    },
    tooltip: {
      displayName: 'Tooltip',
      value: "",
      type: 'text',
      enabled: true
    },
    value: {
      displayName: "Value",
      value: '',
      type: 'number',
    },
    appearance: {
      displayName: "Input appearance",
      value: "standard",
      type: "select"
    },
    viewOnly: {
      displayName: 'View Only Mode',
      description: 'When turned on, user can not edit the value',
      value: false,
      type: 'boolean',
      resetValue: false
    },
    supportedValidators: ['minvalue','maxvalue']
  }
  textFormat: undefined

  constructor(id: number, name: string) {
    super(id, name)
    this.attachCommonConfig(this)
    this.textFormat = TextFormatting.initStyles(JSON.parse(JSON.stringify(TextFormatting.textFormatting())))
  }

  public getMappingConfig(){
    let map = {
      __id: "${panel.id}.${widget.id}.value",
      name: "${panel.name} ${widget.name}",
      dataType: "number"
    }
    return map;
  }

  public getWidgetConfig(){
    let map:any = {
      valuePath: "config.value.value",
      effect: {
        styles: []
      }
    }
    map = this.mergeEffectStyle(map)
    return map;
  }
}

import { Widget } from './baseWidget';
import { TextFormatting } from './textFormat';

export class RichTextInput extends Widget {
  type = 'richtext';
  gridX = 12
  minGridX = 6

  config = {
    props: [
      'placeholder',
      'viewOnly',
      'tooltip',
    ],
    placeholder: {
      displayName: 'Input Placeholder',
      value: '',
      type: 'text'
    },
    value: {
      displayName: 'Text Content',
      value: '',
      type: 'longtext',
      resetValue: ''
    },
    viewOnly: {
      displayName: 'View Only Mode',
      description: 'When turned on, shows formatted text to the user without edit capability',
      value: false,
      type: 'boolean',
      resetValue: false
    },
    tooltip: {
      displayName: 'Tooltip',
      value: "",
      type: 'text',
      enabled: true
    },
  };
  textFormat: any;

  constructor(id: number, name: string) {
    super(id, name)
    this.attachCommonConfig(this);
    this.config['defaultValue'].type = 'longtext';
    this.config['defaultValue'].value = '</p><br><br><br><br><br><br><br><br></p>';
    this.textFormat = TextFormatting.initStyles(JSON.parse(JSON.stringify(TextFormatting.textFormatting())))
  }

  public getMappingConfig(){
    let map = {
      __id: "${panel.id}.${widget.id}.value",
      name: "${panel.name} ${widget.name}",
      dataType: "string"
    }
    return map;
  }

  public getWidgetConfig(){
    let map:any = {
      valuePath: "config.value.value",
      effect: {
        styles: []
      }
    }
    map = this.mergeEffectStyle(map)
    return map;
  }
}

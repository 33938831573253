import { Component, OnInit, Input, Output, ViewChild, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { WidgetUtilityService } from 'src/app/bloom/services/widget-utility.service';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { PageService } from 'src/app/bloom/services/page-service.service';
import { Subject } from 'rxjs';
import { ResourcePermissionService } from 'src/app/shared/services/resource-permission.service';
import { WidgetService } from 'src/app/bloom/services/widget-service.service';
import { FormControl, Validators } from '@angular/forms';
import { ValidationService } from 'src/app/shared/services/validation.service';

@Component({
  selector: 'app-choice',
  templateUrl: './choice.component.html',
  styleUrls: ['./choice.component.css']
})
export class ChoiceComponent extends BaseWidgetComponent implements OnInit, OnChanges {

  contextMenuActions: any;
  selectedOption: string;
  textEditMode: boolean = false;
  hoveredNow: boolean = false;
  styles: any;
  addingItem: boolean = false;
  inputVisible: boolean = false;
  editingHeading: boolean = false;

  availableOptions: any[] = []
  private destroy:any = new Subject();
  pageMeta: any
  fc = new FormControl('')
  validationSubscription: any

  constructor(
    private widgetUtilityService: WidgetUtilityService,
    public pageService: PageService,
    public metaService: MetaService,
    public resourcePermissionService: ResourcePermissionService,
    public widgetService: WidgetService,
    private validationService: ValidationService
  ) {
    super(metaService, pageService, resourcePermissionService)
  }

  ngOnInit(): void {
    super.ngOnInit()

    this.validationSubscription = this.validationService.$validationFeedback.subscribe(data => {
      console.log("validation subscription", data)
      if(data.widgetId !== this.widgetMeta.id) return
      if(data.status == false) {
        this.fc.markAsTouched()
      }
    })

    console.log("choice onInit")
    this.destroy = this.metaService.$contextChanged.subscribe((contextActions: any) => {
      console.log("context changed subscription", contextActions)
      if(contextActions && this.widgetMeta.id == contextActions?.widgetId){
        this.action(contextActions)
      }
    })
    this.generateAvailableOptions()
  }

  ngOnDestroy(): void {
    this.destroy.unsubscribe();
    this.validationSubscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.contextActions?.currentValue){
      this.action(changes.contextActions.currentValue)
    }
    if(changes.widgetMeta?.currentValue){
      this.generateAvailableOptions()
      this.setContextActions()
    }
  }

  initForm(){
    console.log("initForm hit", JSON.parse(JSON.stringify(this.widgetMeta?.config?.value?.value)))
    if(this.widgetMeta?.config?.required?.value){
      this.fc.addValidators(Validators.required);
    }
    this.fc.patchValue(this.widgetMeta?.config?.value?.value || '')
    this.fc.updateValueAndValidity()
  }

  setContextActions(){
    this.contextMenuActions = {
      actions: [
        "bold",
        "underline",
        "italic",
        "color",
        "backgroundColor",
        "alignment",
        "fontSize",
        "fontFamily",
        "class",
        "edit",
      ]
    }
    this.raiseContextMenuActions.emit(this.contextMenuActions)
  }

  action(event) {
    console.log("action is", event)
    switch (event.actionType) {
      case "delete":
        this.onDelete();
        break;
      case "updateStyles":
        if (event?.data) {
          this.widgetMeta = event.data;
          console.log("localMeta changed", this.widgetMeta)
          this.newWidgetMeta.emit(this.widgetMeta)
          // this.pageService.updateWidgetInPage(this.widgetMeta, this.panelId)
        }
        // this.newWidgetMeta.emit(this.widgetMeta)
        super.generateStyles();
        break;
      case 'settingsChanged':
        this.widgetMeta = event.data
        this.generateAvailableOptions()
        // this.newWidgetMeta.next(this.widgetMeta)
        this.pageService.updateWidgetInPage(this.widgetMeta, this.panelId)
        break;

      default:
        break;
    }
  }

  /**
   * adds new static option
   * @param newOption is an object containing name[string], value[string] and default[boolean]
   */
  addItem(newOption: any) {
    console.log("checkbox addItem hit with text:", newOption)

    let optionObject = {
      name: newOption.name,
      value: newOption.value,
      default: newOption.default
    }
    this.widgetMeta.config.availableOptions.staticOptions.push(optionObject)
    this.generateAvailableOptions(true)
    // this.newWidgetMeta.next(this.widgetMeta)
    this.pageService.updateWidgetInPage(this.widgetMeta, this.panelId)
  }

  removeOption(i: number){
    this.widgetMeta.config.availableOptions.staticOptions.splice(i, 1)
    console.log("option removed")
    this.generateAvailableOptions(true)

    console.log("[CHOICE] new widgetMeta:", this.widgetMeta)

    this.pageService.updateWidgetInPage(this.widgetMeta, this.panelId)
  }

  saveNewHeading(newHeaderRef: any) {
    console.log("new title will be saved", newHeaderRef.value)
    if (newHeaderRef.value == '') {
      console.log("empty header received, cant update")
      return
    }
    this.widgetMeta.config.title.value = newHeaderRef.value

    // this.newWidgetMeta.emit(this.widgetMeta)
    this.pageService.updateWidgetInPage(this.widgetMeta, this.panelId)
  }

  radioChanged(event: any, i: number) {
    console.log("radio button selection changed", event, "index", i)
    // this.widgetMeta.config.value = this.widgetMeta.config.availableOptions.staticOptions[i].value;

    //check for conditional form section button
    if(!this.builderMode) this.widgetService.checkForFormSection(this.widgetMeta.config.availableOptions.staticOptions[i], this.widgetMeta);
    this.widgetMeta.config.value.value = event.value;
    this.userInputDetected()
  }


  onClick(event: any) {
    if(!this.builderMode) return
    this.selectedWidgetId = this.widgetMeta.id
    console.log("icon clicked", this.widgetMeta.id)
    this.widgetSelection.emit(this.widgetMeta.id)
  }

  onDelete() {
    console.log("widget ID", this.widgetMeta.id, "will be deleted")
    this.widgetDeletion.emit(this.widgetMeta.id)
  }

  async generateAvailableOptions(noRefetch: boolean = false){
    console.log("generate available options hit", noRefetch)
    console.log("widgetMeta", JSON.parse(JSON.stringify(this.widgetMeta)))
    let staticOptions: any[] = this.widgetMeta.config.availableOptions.staticOptions

    let rawDynamicOptions: any[] = []
    let dynamicOptionItems: any[] = []
    if(noRefetch){
      dynamicOptionItems = this.availableOptions.filter(opt => opt.type == 'dynamic')
      console.log("preserved dynamic options", dynamicOptionItems)
    }else{
      if(this.widgetMeta.config.availableOptions.dynamicOptions.enabled){
        if(!this.widgetMeta.config.availableOptions.dynamicOptions?.userData){
          rawDynamicOptions = await this.widgetUtilityService.fetchDynamicOptions(this.widgetMeta, this.builderMode);
        } else {
          rawDynamicOptions = await this.widgetUtilityService.getBloomUsers();
        }
        dynamicOptionItems = this.widgetUtilityService.processDynamicOptions(rawDynamicOptions, this.widgetMeta)
      }
    }

    if(this.builderMode){
      staticOptions.map(opt => opt['type'] = 'static')
      dynamicOptionItems.map(opt => opt['type'] = 'dynamic')
    }

    let newOptions: any[] = []
    newOptions = newOptions.concat(staticOptions)
    newOptions = newOptions.concat(dynamicOptionItems)
    this.availableOptions = newOptions
    console.log("options", this.availableOptions)

    if (this.widgetMeta.config.value.value == undefined || this.widgetMeta.config.value.value == null || this.widgetMeta.config.value.value == '') {
      let defIndex = this.widgetMeta.config.availableOptions.staticOptions.findIndex(op => op.default)
      if (defIndex > -1) this.widgetMeta.config.value.value = this.widgetMeta.config.availableOptions.staticOptions[defIndex].value
    }

    this.initForm()
    this.userInputDetected()
  }

  userInputDetected() {
    let userInput: any = {
      // dataBindConfig: this.widgetMeta?.dataBindConfig,
      widgetId: this.widgetMeta.id,
      value: this.widgetMeta.config?.value?.value || "",
      validity: this.isInputValid()
    }
    console.log("emitting", userInput)
    this.userInputReceived.emit(userInput);
  }

  isInputValid() {
    // console.log("isInputValid", this.widgetMeta)
    if (this.widgetMeta.config.required?.value) {
      if (this.widgetMeta.config.value.value == undefined || this.widgetMeta.config.value.value == null || this.widgetMeta.config.value.value == '') {
        return false
      } else {
        return true
      }
    } else {
      return true
    }
  }
}

import { Component, OnInit, Inject, Input, Output, NgZone, EventEmitter } from '@angular/core';
import { UntypedFormControl, FormGroup } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { TokenUtil } from 'src/app/core/services/TokenUtil.service';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { PageService } from 'src/app/bloom/services/page-service.service';
import { PanelComponent } from 'src/app/bloom/page/panel/panel.component';
import { Button } from 'src/app/bloom/models/widgetClasses/button';
import { Checkbox } from 'src/app/bloom/models/widgetClasses/checkbox';
import { BoxService } from 'src/app/bloom/services/box-service.service';
import { SearchPanelDialogComponent } from './search-panel-dialog/search-panel-dialog.component';
import { WidgetManager } from 'src/app/bloom/models/WidgetManager';
import { AutomationService } from 'src/app/bloom/services/automation.service';
import { WidgetService } from 'src/app/bloom/services/widget-service.service';
import { ListPanelService } from '../list-panel/list-panel.service';

interface Page {
  number: number,
  size: number,
  total?: number,
  nextPageToken?: string,
  previousPageToken?: string
}

@Component({
  selector: 'app-search-panel',
  templateUrl: './search-panel.component.html',
  styleUrls: ['./search-panel.component.css']
})
export class SearchPanelComponent extends PanelComponent implements OnInit {

  @Input() panelMeta;
  @Input() builderMode;

  @Output() newWidgetMeta = new EventEmitter<any>();

  pageMeta: any;
  boxConfigToken: any;
  boxName: any;
  selectedConnectionId: any;
  selectedBoxId: any;
  selectedBoxName: any;
  selectedBoxAttribute: any = '';
  selectedBoxObjectId: any = '';
  isDataReady: boolean = false;
  isAtrributeReady: boolean = false;
  isReadyToSave: boolean = false;

  boxObjectFunctionInputLists: any = []

  isBoxSelected: boolean = false;
  isBoxObjectSelected: boolean = false;
  boxObjectAttributes: any = {
    names: []
  }
  disableSearchAttributeAdd: boolean = false

  searchAttributesList: any = []
  sortAttributesList: any = []
  selectedSortAttributes: any = []

  // filteredBoxes: Observable<string>[];
  filteredBoxes: any = [];
  filteredBoxObjects: any;

  searchInputWidgets: any[] = []
  sortWidgets: any[] = []
  searchButtonWidgetMeta: any

  newSearch: boolean = true;

  currentPageCode: string;
  margin: any;
  page: Page = {
    number: 1,
    size: 10
  };
  showClear: boolean = false

  // searchInputValues{
  //   attributeId: searchValue,
  //   attributeId: searchValue,
  //   .
  //   .
  // }
  searchInputValues: any = {};

  selectBoxControl = new UntypedFormControl();
  selectBoxObjectControl = new UntypedFormControl();

  constructor(
    // public dialogRef: MatDialogRef<SearchPanelDialogComponent>,
    // @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private metaService: MetaService,
    private boxService: BoxService,
    private _snackBar: MatSnackBar,
    private http: HttpClient,
    private TokenUtil: TokenUtil,
    private pageService: PageService,
    private zone: NgZone,
    private automationService: AutomationService,
    private widService: WidgetService,
    private listPanelService: ListPanelService
  ) {
    super()
  }

  ngOnInit(): void {
    console.log("panel meta received in search panel component: ", JSON.parse(JSON.stringify(this.panelMeta)))

    this.pageService.currentPageCode.subscribe(code => {
      this.currentPageCode = code
    })

    //SUBSCRIBE TO PAGE META
    this.metaService.pageMeta.subscribe(meta => {
      // console.log("search panel onInit: page meta:", meta)
      if (this.currentPageCode == meta.code) {
        this.pageMeta = meta;
      }
    })
    // console.log("panel meta:", this.panelMeta)
    this.searchPanelInit()
  }

  //-----------------------------------------FUNCTIONS--------------------------------------


  searchPanelInit(){
    // console.log("panel meta", JSON.parse(JSON.stringify(this.panelMeta)))
    // console.log("search widgets", JSON.parse(JSON.stringify(this.searchInputWidgets)))

    // let panelWidgets = this.widService.getWidgetsFromPanel(this.panelMeta)
    // console.log("panel widgets", panelWidgets)
    this.searchInputWidgets = this.automationService.generateSearchWidgets(this.panelMeta)
    this.createSortWidgets()
    this.clearWidgetValues()
    // if(!this.searchInputWidgets?.length){
    //   console.log("will generate widgets")
    //   this.searchInputWidgets = this.automationService.generateSearchWidgets(this.panelMeta)
    // }else{
    //   this.searchInputWidgets = panelWidgets
    //   // console.log("initialized old widgets", this.searchInputWidgets)
    // }
    // this.panelMeta['widgets'] = this.searchInputWidgets
    // super.migrateExistingPanelMeta()
    // console.log("migrated meta", JSON.parse(JSON.stringify(this.panelMeta)))
    // this.panelMeta['widgets'] = this.searchInputWidgets

    // this.updatePageMeta()
    this.newPanelMeta.emit(this.panelMeta)

    let lastSearchValuesBackup = this.listPanelService.getFilterBackup(this.panelMeta.id)
    for (let attrId in lastSearchValuesBackup) {
      let f = lastSearchValuesBackup[attrId]
      this.searchInputWidgets.forEach(w => {
        if (w.id.includes(f.attribute.__id) && f.value) {
          let newWid = WidgetManager.getWidget(w.type, w.id, w.name)
          Object.keys(w).forEach(prop => newWid[prop] = w[prop])
          newWid.setValue(f.value)
        }
      })
    }
    this.initializeSearchInputValues()
    this.initializeSortAttributes()
    // console.log("sort attribute checkbox widget created", wid)

    //search button widget meta creation
    // this.searchButtonWidgetMeta = new Button(this.panelMeta.id, "search_button_for" + this.panelMeta.id)
    // console.log("from button directly", new Button(this.panelMeta.id, "search_button_for" + this.panelMeta.id))
    this.searchButtonWidgetMeta = WidgetManager.getWidget('button', "search_button_for" + this.panelMeta.id, "search_button_for" + this.panelMeta.id)
    // console.log("button meta fetched", JSON.parse(JSON.stringify(this.searchButtonWidgetMeta)))
    this.searchButtonWidgetMeta.config.buttonText.value = this.panelMeta.searchButtonText
    this.searchButtonWidgetMeta.config['buttonIcon']['value'] = true
    this.searchButtonWidgetMeta.config['buttonIcon']['iconName']['value'] = 'search'
    this.searchButtonWidgetMeta.effectStyle = {padding: "6px"};
    console.log("searchButtonWidgetMeta", this.searchButtonWidgetMeta);
    // }

    if(this.panelMeta.margin){
      this.margin = this.panelMeta.margin
    }
  }

  createSortWidgets() {
    this.sortWidgets = []
    //for sort attributes, create a checkbox widget with check list labels as attribute name
    //keep it checked and disabled if userSelectSortAtrribute is false
    console.log("this.panelMeta.sortAttribute", this.panelMeta.sortAttributes)
    let wid = new Checkbox(Date.now(), "sortAttributes_for" + this.panelMeta.id)
    wid.config['title'].value = 'Sort by'
    //push it to items array
    wid.config.availableOptions.staticOptions = [];
    this.panelMeta.sortAttributes?.forEach(attr => {
      //create a checkbox item for sort attribute
      let item = {
        value: attr.__id,
        name: attr.__id,
        default: this.panelMeta.userSelectSortAtrribute ? false : true
      }
      wid.config.availableOptions.staticOptions.push(item)
    });
    this.sortWidgets.push(wid)
    console.log("sortWidgets", this.sortWidgets)
  }

  initializeSearchInputValues() {
    this.panelMeta.searchAttributes.forEach(attr => {
      this.searchInputValues[attr.__id] = {
        value: '',
        dataType: attr.dataType,
        attribute: attr
      }
    });
    console.log("searchInputValues initialized", this.searchInputValues)
  }

  initializeSortAttributes() {
    //initialize sort attributes based on panelMeta
    // if (this.panelMeta.userSelectSortAtrribute == true) {
    //   this.selectedSortAttributes = []
    // } else {  //if user not allowed to search, all sort attributes must be already selected and disabled, so just copy the array
      this.selectedSortAttributes = this.panelMeta?.sortAttributes || [];
    // }
  }

  executeManualSearch() {
    this.newSearch = true
    // this.zone.run(() => {
      // this.executeSearch(1, this.panelMeta.resultSize)
      this.sendToListPanel()
    // })
  }

  sendToListPanel(){
    let resObj: any = {
      searchInputValues: this.constructFilterValues(),
      sortAttributes: this.panelMeta?.sortAttributes || [],
      panel: this.panelMeta,
      newSearch: this.newSearch,
      searchPanelId: this.panelMeta.id,
      outputListPanelId: this.panelMeta.outputListPanelId,
      boxId: this.panelMeta.boxId,
      boxObjectId: this.panelMeta.boxObjectId
    }
    console.log("will next", resObj)
    console.log("this.pageService.searchResultBuffer", this.pageService.searchResultBuffer)

    // set filter backup
    this.listPanelService.setFiltersBackup(resObj.searchInputValues, this.panelMeta.id)
    console.log("set filter backup", resObj.searchInputValues)

    this.pageService.searchResultBuffer.next(resObj)
  }

  constructFilterValues(){
    if (!this.panelMeta.commonSearchField) return this.searchInputValues

    let filterObj = {
      operator: "or",
      filterItems: []
    }
    Object.keys(this.searchInputValues).forEach(f => {
      filterObj.filterItems.push(this.searchInputValues[f])
    })
    console.log("filter obj created", filterObj)
    return filterObj
  }

  searchValueIncoming(val: any, widgetMeta?: any) {
    if(this.panelMeta.commonSearchField){
      this.panelMeta.searchAttributes.forEach(attr => {
        console.log("dealing", attr)
        if(!attr.isDrillDown){
          this.searchInputValues[attr.__id] = {
            value: val.value,
            dataType: attr.dataType,
            attribute: attr
          }
        }else{
          attr.nestedProperties.forEach(np => {
            this.searchInputValues[attr.__id + "." + np.path] = {
              value: val.value,
              dataType: np.dataType || 'string',
              attribute: { __id: attr.__id + '.' + np.path, name: attr.name + '.' + np.path }
            }
          })
        }
        console.log("added into searchInputValues", JSON.parse(JSON.stringify(this.searchInputValues || {})))
      })
      return
    } else {
      let i;
      let widId = widgetMeta.id
      let attrId = widId.split('-')[1]
      let attr;
      let attrDataType;
      let idParts = attrId?.split('.')
      i = this.panelMeta.searchAttributes.findIndex(attr => attr.__id == idParts[0])
  
      console.log("search value for attribute, index", i, this.panelMeta?.searchAttributes[i]?.__id, "is:", val)
  
      if(idParts.length == 1){
        attrDataType = this.panelMeta.searchAttributes[i].dataType
        attr = this.panelMeta.searchAttributes[i]
      }else{
        let nestedProp = this.panelMeta.searchAttributes[i]?.nestedProperties?.find(np => np.path == idParts[1])
        attrDataType = nestedProp?.dataType || 'string'
        attr = nestedProp
        attr['__id'] = attrId + '.' + nestedProp.path
      }
      // console.log("attrId", th)
      // console.log("searchAttributes", this.panelMeta.searchAttributes)
  
      //its a mapping from attributeId to search value and dataType
      this.searchInputValues[attrId] = {
        value: val.value,
        dataType: attrDataType,
        attribute: this.panelMeta.searchAttributes[i]
      }
    }

    let foundNonEmpty = false
    Object.keys(this.searchInputValues).forEach(key => {
      if(this.searchInputValues[key].value) foundNonEmpty = true
    })
    if(foundNonEmpty) this.showClear = true
    else this.showClear = false
  }


  // clearSearchInputs(){
  //   // Object.keys(this.searchInputValues).forEach(key => {
  //   //   this.searchInputValues[key].value = ""
  //   // })
  //   this.clearWidgetValues()
  // }

  sortOrderChange(sort, order){
    sort.order = order;
  }

  sortAttributeChanged(checkboxEmitterData: any, sort:any) {
    sort.checked = checkboxEmitterData.checked;
    // if (checkboxEmitterData.checked) {
    //   //add the element being checked
    //   this.selectedSortAttributes.push(checkboxEmitterData.item.content)
    // } else {
    //   //remove the element that is being unchecked
    //   var index = this.selectedSortAttributes.indexOf(checkboxEmitterData.item.content);
    //   if (index !== -1) {
    //     this.selectedSortAttributes.splice(index, 1);
    //   }
    // }
  }

  openSettings() {
    let dialogRef = this.dialog.open(SearchPanelDialogComponent, {
      minHeight: '50vh',
      minWidth: '80vw',
      maxHeight: '90vh',
      data: {
        firstHit: false,
        panelMeta: this.panelMeta,
        pageMeta: this.pageMeta
      }
    })

    dialogRef.afterClosed().subscribe(data => {
      if (!data) {
        console.log("search panel configuration dialog closed unexpectedly")
      } else {
        console.log("search panel config dialog resolved", data)
        this.panelMeta = data.searchPanelMeta;

        let sortAttributes = JSON.parse(JSON.stringify(this.panelMeta.sortAttributes));

        if (data['listPanelMeta']) {
          let searchPanelIndex = this.pageMeta.panels.findIndex(panel => panel.id == data['searchPanelMeta'].id)
          this.pageMeta.panels.splice(searchPanelIndex + 1, 0, data['listPanelMeta'])
        }

        this.searchPanelInit()
      }
    })
  }

  emitNewWidgetMeta(data, i){
    console.log("panelMeta", JSON.parse(JSON.stringify(this.panelMeta.layoutMap)))
    console.log("new widget meta received for widget", i, JSON.parse(JSON.stringify(data)))
    let temp = WidgetManager.getWidget(data.type, data.id, data.name)
    Object.keys(data).forEach(prop => {
      console.log("prop", prop, "right", JSON.parse(JSON.stringify(data[prop] || "")))
      temp[prop] = data[prop]
    })

    if(temp.type !== 'checkbox') temp.setValue('')
    console.log("new", JSON.parse(JSON.stringify(temp)))
    console.log("original", JSON.parse(JSON.stringify(data)))
    // this.panelMeta.widgets[i] = data
    // this.newWidgetMeta.emit(temp)

    let updateColId, updateRowId, index;
    this.panelMeta.layoutMap?.list?.forEach(col => {
      this.panelMeta.layoutMap[col].list?.forEach(row => {
        console.log("col", col, "row", row)
        this.panelMeta.layoutMap[col]?.[row]?.elements.forEach((wid, i) => {
          console.log("wid", JSON.parse(JSON.stringify(wid)))
          if(wid.id == data.id){
            console.log("will update widget", JSON.parse(JSON.stringify(wid)), "with", JSON.parse(JSON.stringify(data)))
            // wid = JSON.parse(JSON.stringify(data))
            updateColId = col
            updateRowId = row
            index = i
          }
        })
      })
    })

    this.panelMeta.layoutMap[updateColId][updateRowId].elements[index] = JSON.parse(JSON.stringify(data))
    console.log("emitting", JSON.parse(JSON.stringify(this.panelMeta)))
    this.newPanelMeta.emit(this.panelMeta)
    console.log("panel meta updated", JSON.parse(JSON.stringify(this.panelMeta)))
  }

  // clears the widgets values in search panel
  clearWidgetValues(){
    console.log("clear widget values hit", JSON.parse(JSON.stringify(this.panelMeta)))
    // let wids = this.pageService.getWidgetsFromPanel(this.panelMeta)
    // console.log("widgets to clear", wids)
    this.searchInputWidgets.forEach((w, i) => {
      console.log("creating clone of ", w)
      let temp = WidgetManager.getWidget(w.type, w.id, w.name)
      Object.keys(w).forEach(prop => temp[prop] = w[prop])

      temp.setValue('${reset}')
      w = temp
      console.log("widget cleared", JSON.parse(JSON.stringify(w)))
    });
    // this.panelMeta['widgets'] = wids
    // super.migrateExistingPanelMeta()

    // this.searchInputWidgets = this.automationService.generateSearchWidgets(this.panelMeta)
    console.log("search widgets after clearing", JSON.parse(JSON.stringify(this.searchInputWidgets)))
    // this.searchPanelInit()
  }

  stopProp(event) {
    console.log("click detected in search result table")
    // event.stopPropagation()
  }
}

import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { UntypedFormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, startWith } from 'rxjs/operators';

import { MetaService } from '../../services/meta-service';
import { TokenUtil } from '../../../core/services/TokenUtil.service'
import { PageService } from '../../services/page-service.service';
import { BoxService } from '../../services/box-service.service';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { environment } from 'src/environments/environment';
import { WidgetManager } from '../../models/WidgetManager';
import { ActionManager } from '../../models/Action/ActionManager';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { BoxInstance } from 'src/app/core/boxInstances/BoxInstance';
import { ActionConditionComponent } from './action-condition-popup/action-condition-popup.component';
import { ActionServiceGUIUtility } from '../../models/Action/ActionServiceGUIUtility';

@Component({
  selector: 'app-widget-action-config',
  templateUrl: './widget-action-config.component.html',
  styleUrls: ['./widget-action-config.component.css']
})
export class WidgetActionConfigComponent implements OnInit {

  @Input() widgetMeta;
  @Input() panelId;
  @Input() config;


  visibilityOptions: any = ['vis_one', 'vis_two', 'vis_three']
  selectedVisibility: any = ''
  domEvents: any = ['click', 'change', 'mousedown']
  selectedDomEvent: any = this.domEvents[0]
  eventScopes: any = ['client', 'server']
  selectedEventScope: any = ''

  filteredBoxes: any;
  filteredBoxObjects: any;

  selectBoxControl = new UntypedFormControl();
  selectBoxObjectControl = new UntypedFormControl();

  isBoxSelected: boolean = false;
  isGotBoxObjectFunction: boolean = false;

  pageMeta: any;
  pageModel: any;

  selectedBoxId: any;
  selectedBoxName: any;
  selectedBoxObject: any;
  selectedConnectionId: any;
  selectedBoxAttribute: any;
  selectedBoxObjectFunction: any;
  boxName: any;
  boxConfigToken: any;
  boxObjectList: any;
  boxObjectFunctions: any = [];
  boxObjectAttributes: any;
  boxObjectFunctionInputLists: any = [];
  isGotBoxAttributes: boolean = false;
  isBoxObjectListReady: boolean = false;
  isBoxObjectSelected: boolean = false;
  isBoxObjectFunctionSelected: boolean = false;
  isSourceMapGenerated: boolean = false;

  connectionList: any[] = []
  connectionListRaw: any[] = []
  connectionListLength: number = 0
  isReceivedConnections: boolean = false;

  isReceivedBoxObjects: boolean = false;
  boxObjects: any = [];

  widgetManager: any = WidgetManager;

  availableEvents: any;
  availableActions: any;
  actionConfig: any;

  actionDetailMap: any = {
    navigation: {
      name: "Navigation",
      desc: "Navigate to any page"
    },
    application: {
      name: "Application",
      desc: "Execute action on any application"
    },
    widget: {
      name: "Widget",
      desc: "Change the widget effects or change the values of widget"
    },
    flow: {
      name: "Flow",
      desc: "Execute a flow"
    }
  }

  actionDetailsArray = Object.entries(this.actionDetailMap).map(([key, value]: any) => ({
    key,
    name: value.name,
    desc: value.desc
  }));

  widgetActionChangeSub: any;
  displayConfig: { connectionFocus: boolean; };

  constructor(
    public metaService: MetaService,
    private boxService: BoxService,
    private _snackBar: MatSnackBar,
    private http: HttpClient,
    private TokenUtil: TokenUtil,
    private pageService: PageService,
    private dialog: MatDialog,
    private connectionService: ConnectionService,
    public actionManager: ActionManager,
    public actionServiceGUIUti: ActionServiceGUIUtility
  ) { }

  ngOnInit(): void {

    console.log("action config this.widgetMeta", this.widgetMeta)
    if(!this.widgetMeta.actionConfig) this.widgetMeta.actionConfig = {};
    // if(this.widgetMeta.actionConfig) {
    //   if(this.widgetMeta.actionConfig.action) this.onActionChange()
    // }
    if (!this.widgetMeta.actionConfig.actions) this.addNewAction()

    if(this.widgetMeta.actionConfig?.actions?.length) this.actionServiceGUIUti.initActionObjectOptions(this.widgetMeta.actionConfig.actions);

    this.metaService.pageMeta.subscribe(meta => {
      // console.log("widget action config onInit", meta)
      this.pageMeta = meta;
      // this.generateBoxObjectList()
    })

    this.pageModel = this.pageService.getPageModel()

    this.filteredBoxes = this.selectBoxControl.valueChanges.pipe(
      startWith(''),
      map(value => this._boxFilter(value))
    );

    this.filteredBoxObjects = this.selectBoxObjectControl.valueChanges.pipe(
      startWith(''),
      map(value => this._boxObjectFilter(value))
    );

    this.widgetActionChangeSub = this.pageService.widgetActionChange.subscribe(async(event) => {
      console.log("widgetActionChangeSub", event);
      if(event?.action == "add_action_map"){
        this.addNewAction(event)
      }
    });

    //initialize boxName based on dataBindConfig in widgetMeta
    // if(this.widgetMeta.actionConfig !== undefined){
    //   this.pageMeta.connections.names.forEach(name => {
    //     if(this.pageMeta.connections[name]._id == this.widgetMeta.actionConfig?.connectionId){
    //       console.log("found name", name)
    //       this.boxName = name
    //     }
    //   });
    // }

    this.getAllConnection()
    this.getEvents(this.widgetMeta);
    this.getActions()

  }

  isHidden(i: number, actions: any){
    let bool = false;
    if(this.config?.hideLastAction && i == actions.length - 1) bool = true
    return bool;
  }

  isActionDisabled(index: number): boolean {
    let bool = false;
    if(this.config?.disableActions.length && this.config?.disableActions.includes(index + 1)) bool = true;
    return bool;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      console.log("changes in wac",  changes);
    }
  }

  isActionEnabled(index: number, event?: any): boolean {
    if(event == undefined){ 
      //initially when the automation actions load, event is not fired. for each action if the status is active then the switch will be enaabled by default
      if(this.widgetMeta?.actionConfig?.actions[index]?.active) return true
    } else {
      if(event){
        this.widgetMeta.actionConfig.actions[index].active = true;
        return true;
      } else {
        this.widgetMeta.actionConfig.actions[index].active = false;
        return false;
      }
    }
  }

  ngOnDestroy(){
    // this.widgetActionChangeSub
  }

  addNewAction(option?: any) {
    console.log("origin option", option)
    if (!this.widgetMeta.actionConfig.actions) this.widgetMeta.actionConfig.actions = [];

    let actionObj:any = {
      "event": this.config?.defaultEvent || option?.event || "click",
      "action": this.config?.defaultAction || option?.action || "application",
      "actionMap": {
        boxId: ""
      }
    }

    if(option?.action == "add_action_map" && option?.actionMap) {
      actionObj = option?.actionMap;
    }
    actionObj['active'] = true;
    // attach origin
    let parentPanel = this.pageMeta?.panels?.find(p => p.id == this.panelId)
    if(parentPanel && parentPanel.type == "formpanel"){
      actionObj.actionMap['origin'] = "formpanel"
    }
    console.log("origin added", actionObj)
    // let actions = JSON.parse(JSON.stringify(this.widgetMeta.actionConfig.actions));
    // actions.push(actionObj);
    if(this.config?.hideLastAction){
      this.widgetMeta.actionConfig.actions.splice(this.widgetMeta.actionConfig.actions.length - 1, 0, actionObj);
      this.actionServiceGUIUti.spliceActionObject(actionObj);
    } else {
      this.actionServiceGUIUti.addActionObject(actionObj);
      this.widgetMeta.actionConfig.actions.push(actionObj);
    }
    console.log("origin actions", this.widgetMeta.actionConfig.actions)
  }

  onPanelOpened(i){
    console.log("panel opened", i);
    this.actionServiceGUIUti.openedActionIndex = i;
  }

  removeAction(actions, i) {
    actions.splice(i, 1);
    this.actionServiceGUIUti.deleteActionOptions(i);
  }

  getEvents(widgetMeta){
    console.log("on get events");
    this.availableEvents = this.widgetManager.getWidget(widgetMeta.type).getEvents();
    console.log("on get events", this.availableEvents);
  }

  getActions(){
    this.availableActions = this.actionManager.getActions();
    console.log("available actions", this.availableActions)
  }

  // onActionChange(){
  //   this.actionConfig = this.actionManager.getAction(this.widgetMeta.actionConfig.action).getActionConfiguration()
  //   console.log("this.actionConfig ", this.actionConfig )
  //   if(this.widgetMeta.actionConfig.action && !this.widgetMeta.actionConfig.actionMap) this.widgetMeta.actionConfig.actionMap = {};
  // }



  //-----------------------------------------FILTERS------------------------------------

  //filter for box names autocomplete from the connections
  private _boxFilter(value: string): string[] {
    const filterValue = value.toLowerCase();
    let boxes: any = []

    //create an array of connectionTypes/boxIds by going over boxObjectList
    // this.pageMeta?.connections?.names.forEach(box => {
    //   boxes.push(box)
    // });

    this.connectionList.forEach(box => {
      boxes.push(box.name)
    })

    return boxes.filter(option => option.toLowerCase().includes(filterValue));
  }


  //filter for box Objects autocomplete for the selected box
  private _boxObjectFilter(value: string): string[] {
    const filterValue = value.toLowerCase();
    let boxObjectIds: any = []
    console.log()

    //create an array of connectionTypes/boxIds by going over boxObjectList
    // this.pageMeta?.connections[this.selectedBoxName]?.boxObjects.forEach(boxObject => {
    //   // if(boxObject == this.selectedBox)
    //   boxObjects.push(boxObject)
    // });
    // console.log("box objects array:", boxObjects)

    this.boxObjects.forEach(boxObject => {
      boxObjectIds.push(boxObject.__id)
    });

    return boxObjectIds.filter(option => option.toLowerCase().includes(filterValue));
  }

  async onConditionClick(e, action){
    e.stopPropagation();

    var actionMap = this.dialog.open(ActionConditionComponent, {
      minWidth: "60%",
      data: {
        widgetMeta: this.widgetMeta,
        action: action
      }
    });
    var actionMapResult = await actionMap.afterClosed().toPromise();
    action = actionMapResult.action;

  }



  //--------------------------------- FUNCTIONS ------------------------------

  getAllConnection() {
    console.log('IN SEARCH PANEL CONFIG: HIT GETALLCONNECTIONS')
    if (!this.connectionService.workSpaceId) {
      console.log("workspace ID not found in organization service, cant fetch connections, need to sign in")

      //close the dialog by simulating click on close button
      // this.closeButton._elementRef.nativeElement.click()

      //redirect to homepagecd
      // console.log("navigating to home")
      // this.router.navigate(['../../'], { relativeTo: this.route })

      return
    }
    let that = this;
    let url = `${environment.SERVER_BASE_URL}/connection/${this.connectionService.workSpaceId}`;
    let token = this.TokenUtil.getStatelessToken()
    const headers = new HttpHeaders().set(
      'Authorization',
      'PreAuthenticatedToken ' + this.connectionService.preAuthenticatedToken
    );

    that.http.get(url, { headers }).subscribe(
      (response: any) => {
        console.log('IN SEARCH PANEL CONFIG: CONNECTIONS RECEIVED', response.data)
        this.connectionListLength = response.data?.length | 0;
        this.connectionListRaw = response.data;

        response.data.forEach(connection => {
          let connectionData = {
            name: connection.name,
            boxId: connection.box_id,
            _id: connection._id,
            boxObjects: [],
            boxConfigToken: connection.box_token
          }
          this.connectionList.push(connectionData)
        });

        console.log("connectionList initialized", this.connectionList)

        this.isReceivedConnections = true
      },
      (error) => {
        console.log(error);
      }
    );
  }

  /**
   * scans through pageMeta.connections and generates a list of objects
   * { boxType, boxObject}
   * @returns works on this.boxObjectList
   */
  generateBoxObjectList() {
    this.boxObjectList = []
    this.pageMeta?.connections?.names.forEach(conn => {
      this.pageMeta?.connections?.[conn]?.boxObjects?.forEach(boxObject => {
        let obj = {
          connectionType: this.pageMeta?.connections?.[conn].boxId,
          connectionName: conn,
          boxObject: boxObject,
          boxConfigToken: this.pageMeta?.connections?.[conn].boxConfigToken
        }
        this.boxObjectList.push(obj)
      });
    });
    this.isBoxObjectListReady = true;
    console.log("boxObjectList generated", this.boxObjectList)
  }


  boxSelected(event: any) {
    console.log("selected box", event.option.value)
    this.selectedBoxName = event.option.value

    // this.selectedConnectionId = this.pageMeta.connections[this.selectedBoxName]._id
    // this.selectedBox = this.pageMeta.connections[this.selectedBoxName].boxId
    this.isBoxSelected = true;

    this.connectionList.forEach(box => {
      if (box.name == this.selectedBoxName) {
        this.selectedBoxId = box.boxId
        this.boxConfigToken = box.boxConfigToken
        this.selectedConnectionId = box.connectionId
      }
    })
    this.triggerChange()
    this.getBoxObjects()
    //once box is selected, work on filteredBoxObjects
    // this.filteredBoxObjects = this.selectBoxObjectControl.valueChanges.pipe(
    //   startWith(''),
    //   map(value => this._boxObjectFilter(value))
    // );

    //extract boxCOnfig token
    // this.boxConfigToken = this.pageMeta.connections[this.selectedBoxName].boxConfigToken
  }

  /** --------------------------------------getBoxObjects----------------------------------
   * once box is selected, this fn is called to get the box objects of the selected box
   * uses this.selectedBoxId and this.boxConfigToken
   */
  getBoxObjects() {
    if (!this.isBoxSelected) {
      console.log("box not selected, cant fetch box objects")
      return
    }

    this.boxService.getBoxObjects(this.selectedConnectionId)
      .then(boxObjects => {
        console.log("box objects received", boxObjects)
        this.boxObjects = boxObjects;
        this.isReceivedBoxObjects = true
      })
      .catch(err => {
        console.error("searchPanelDialog: getBoxObjects()", err)
        throw err
      })
  }

  /**
   * fired when box object is selected
   * @param event
   */
  boxObjectSelected(event: any) {
    console.log("box object selected", event.option.value)
    this.selectedBoxObject = event.option.value
    this.isBoxObjectSelected = true

    //fetch box attributes to pass to configure source map dialog's datas
    this.getAttributes()
      .then(res => {
        // this.generateSourceMapping()
      })
      .catch(err => {
        console.log("could not fetch attributes", err)
      })

    //fetch box object functions
    this.getBoxObjectFunctions()
  }


  /**
   * fires when user selects a boxObjectFunction from the dropdown
   * @param event
   * @returns
   */
  boxObjectFunctionSelection(event: any) {
    console.log("selected box object", this.selectedBoxObject)
    console.log("boxObjectfunctionSelection hit", event.value)
    this.selectedBoxObjectFunction = event.value;
    this.isBoxObjectFunctionSelected = true;
    this.triggerChange()
  }


  /**
   * fetches available box object functions for selected box object
   * @param uses the information in this.data.boxObject
   * @returns populates this.boxObjectFunctions array and sets isGotBoxObjectFunction
   */
  async getBoxObjectFunctions() {
    console.log("getBoxObjectFunctions hit", this.selectedBoxObject, "AND", typeof this.selectedBoxObject)
    if (this.selectedBoxObject) {
      let that = this;
      // that.pgService.spinner = true;
      var endpoint = `/${this.selectedBoxId}/${this.selectedBoxObject}/functions`;
      let url = await BoxInstance.getInstance(endpoint);
      console.log(url);

      var token = this.TokenUtil.getStatelessToken();
      const headers = new HttpHeaders()
        .set('Authorization', `Bearer ${token}`)
        .set('boxConfigToken', `${this.boxConfigToken}`)
        .set("Content-Type", "application/json" );

      await that.http.get(url + endpoint, { headers: headers }).subscribe(
        (response: any) => {
          if (response) {
            // this.isConfig = true;
            console.log('getBoxObjectFunctions response', response);
            console.log("box Object", this.selectedBoxObject)
            this.boxObjectFunctions = response.functions;
            this.isGotBoxObjectFunction = true;
          }
          // that.pgService.spinner = false;
        },
        (error) => {
          console.log('Error while getting Box object functions', error);
          this.boxObjectFunctions = [];
          this._snackBar.open('Error while getting Box object functions!!', 'OK');
          // that.pgService.spinner = false;
        }
      );
    } else {
      this.boxObjectFunctions = [];
      this._snackBar.open('box object not set');
    }
  }


  /**
  * fetches the attributes associated with selected boxObjectFunction
  * and puts it in a convenient data structure
  * boxObjectAttributes = {
  *   ids: ["attrId1", "attrid2"],
  *   attrId1: {
  *     dataType: '',
  *     value: ''
  *   },
  *   attrId2: {
  *     dataType: '',
  *     value: ''
  *   },
  * }
  */
  getAttributes() {

    return new Promise(async (resolve, reject) => {
      if (!this.isBoxObjectSelected) {
        console.log("boxObject not selected")
        return
      }
      console.log("get attributes hit, current box object", this.selectedBoxObject)
      let payload = {
        parameters: {
          object: this.selectedBoxObject
        }
      }
      console.log("payload prepared", payload)

      let token = this.TokenUtil.getStatelessToken()
      let headers = new HttpHeaders()
        .set('Authorization', `Bearer ${token}`)
        .set('boxConfigToken', this.boxConfigToken)
        .set("Content-Type", "application/json" );

      // let boxId = this.pageMeta.connections[this.selectedBox].boxId

      let endpoint = `/${this.selectedBoxId}/getattributes`;
      let url = await BoxInstance.getInstance(endpoint);
      console.log('url to hit', url)
      console.log('payload', payload);
      console.log('headers', headers);

      this.http
        .post(url + endpoint, payload, { headers: headers })
        .subscribe(
          (response: any) => {
            if (!response) {
              console.log("empty response received")
              return
            }
            console.log('Attributes received', response);

            //map the response in suitable data structure
            let boxObjectAttributes = {
              names: []
            }
            response.result.forEach(attrObj => {
              boxObjectAttributes.names.push(attrObj.name)
              boxObjectAttributes[attrObj.name] = {
                dataType: attrObj.dataType,
                value: ''
              }
            });
            this.boxObjectAttributes = boxObjectAttributes
            this.isGotBoxAttributes = true;

            // this.createAttributeForm(this.boxObjectAttributes)

            console.log("check map", this.boxObjectAttributes)

            //SAVE ATTRIBUTES DATA IN PAGESERVICE (move these 3 lines out of here maybe)
            // console.log("will call this.pageService.saveAttribute()")
            // this.pageService.saveAttributes(connectionId, this.selectedBoxObject, this.boxObjectAttributes)

            resolve(boxObjectAttributes);
          },
          (error) => {
            console.log('Error while getting attributes', error);
            reject(false)
          }
        );
    })
  }


  actionReorder(event: CdkDragDrop<string[]>){
    console.log(event)
    moveItemInArray(this.widgetMeta.actionConfig.actions, event.previousIndex, event.currentIndex);
    this.actionServiceGUIUti.moveItemInArray(event.previousIndex, event.currentIndex);
    this.triggerChange()
  }

  triggerChange(){
    this.metaService.userMadeChanges.next(true)
  }

  // generateSourceMapping() {
  //   console.log("hit generate source map")
  //   let attributeMap: any = {
  //     names: []
  //   };

  //   this.boxObjectAttributes.names.forEach(attr => {
  //     console.log("attribute name", attr)
  //     if (attr !== '_id') {
  //       attributeMap.names.push(attr)
  //       attributeMap[attr].mapped = false
  //       attributeMap[attr] = this.boxObjectAttributes[attr]
  //       attributeMap[attr]['sourceConnectionId'] = ''
  //       attributeMap[attr]['sourceBoxObject'] = ''
  //       attributeMap[attr]['sourceBoxAttribute'] = ''
  //     }
  //   });

  //   this.isSourceMapGenerated = true
  //   console.log("map generated", attributeMap)

  //   //reflect the widget meta change in the page meta
  //   this.widgetMeta['actionSourceMap'] = attributeMap

  //   this.pageMeta.panels.forEach(panelMeta => {
  //     if (panelMeta.id == this.panelId) {
  //       panelMeta.widgets.forEach(widgetMeta => {
  //         if (widgetMeta.id == this.widgetMeta.id) {
  //           widgetMeta = this.widgetMeta
  //         }
  //       });
  //     }

  //     //save in database
  //     this.MetaService.update(this.pageMeta)
  //       .then(res => {
  //         console.log("saved action config")
  //       })
  //       .catch(error => {
  //         console.error("error occured in saving action config", error)
  //       })
  //   });
  // }

  // onChangeBoxObjectFunction() {
  //   this.boxObjectFunctionInputLists = [];
  //   let lists = this.selectedBoxObjectFunction?.input?.list || [];
  //   for (var i = 0; i < lists.length; i++) {
  //     var obj = {};
  //     obj['name'] = lists[i];
  //     obj['value'] = '';
  //     this.boxObjectFunctionInputLists.push(obj);
  //   }
  //   console.log('boxObjectFunctionInputLists', this.boxObjectFunctionInputLists);

  //   this.isReadyToSave = true;
  // }


  // onChangeBoxObjectFunction() {
  //   this.boxObjectFunctionInputLists = [];
  //   let lists = this.selectedBoxObjectFunction?.input?.list || [];
  //   for (var i = 0; i < lists.length; i++) {
  //     var obj = {};
  //     obj['name'] = lists[i];
  //     obj['value'] = '';
  //     this.boxObjectFunctionInputLists.push(obj);
  //   }
  //   console.log('boxObjectFunctionInputLists', this.boxObjectFunctionInputLists);

  //   this.isReadyToSave = true;
  // }


  // configureSourceMap() {
  //   console.log("configureSourceMap hit")
  //   let data = {
  //     widgetMeta: this.widgetMeta,
  //     panelId: this.panelId,
  //     connectionId: this.selectedConnectionId,
  //     boxObject: this.selectedBoxObject,
  //     boxObjectAttributes: this.boxObjectAttributes
  //   }
  //   console.log("data to send to source map dialog", data)
  //   this.openDialog()
  //     .then(data => {
  //       console.log("configure source map resolved with", data)

  //       //save basic action config
  //       this.saveActionConfig()

  //       //save in widgetMeta
  //       this.widgetMeta.actionConfig.sourceMap = data
  //       console.log("after adding source map:", this.widgetMeta.actionConfig)

  //       this.modifyPageMeta()
  //       this.savePageMeta()
  //     })
  //     .catch(err => {
  //       console.log("error in configuring source map", err)
  //     })
  // }


  /**
   * will pass 4 things to the dialog
   * 1. this.widgetMeta
   * 2. this.selectedConnectionId
   * 3. this.selectedBoxObject
   * 4. this.boxObjectAttributes
   * 5. this.panelId
   */
  // async openDialog() {
  //   return new Promise((resolve, reject) => {
  //     const dialogRef = this.dialog.open(ConfigureSourceMapComponent, {
  //       width: '800px',
  //       data: {
  //         widgetMeta: this.widgetMeta,
  //         panelId: this.panelId,
  //         connectionId: this.selectedConnectionId,
  //         boxObject: this.selectedBoxObject,
  //         boxObjectAttributes: this.boxObjectAttributes
  //       }
  //     });

  //     dialogRef.afterClosed().subscribe(result => {
  //       console.log('Configure source map dialog was closed', result);
  //       if (result) {
  //         resolve(result)
  //       } else {
  //         reject("configure source map closed with invalid data")
  //       }
  //     });
  //   })
  // }

  // saveActionConfig() {
  //   console.log("save action config hit")
  //   console.log("boxObject", this.selectedBoxObject)

  //   let actionConfig = {
  //     event: this.selectedDomEvent,
  //     eventScope: this.selectedEventScope,
  //     connectionId: this.selectedConnectionId,
  //     boxId: this.selectedBoxId,
  //     boxObject: this.selectedBoxObject,
  //     boxObjectFunction: this.selectedBoxObjectFunction,
  //     inputLists: this.boxObjectFunctionInputLists
  //   }
  //   console.log("action config initialized", actionConfig)
  //   let url = `${this.boxUrl}/${actionConfig.boxId}/${actionConfig.boxObject}/${actionConfig.boxObjectFunction}`
  //   console.log("url is", url)

  //   this.widgetMeta['actionConfig'] = actionConfig

  //   //reflect the widget meta change in the page meta
  //   this.modifyPageMeta()

  //   //save in database
  //   this.savePageMeta()
  // }

  // modifyPageMeta() {
  //   this.pageMeta.panels.forEach(panelMeta => {
  //     if (panelMeta.id == this.panelId) {
  //       panelMeta.widgets.forEach(widgetMeta => {
  //         if (widgetMeta.id == this.widgetMeta.id) {
  //           widgetMeta = this.widgetMeta
  //         }
  //       });
  //     }
  //   });
  // }

  // savePageMeta() {
  //   this.metaService.update(this.pageMeta)
  //     .then(res => {
  //       console.log("saved action config")
  //     })
  //     .catch(error => {
  //       console.error("error occured in saving action config", error)
  //     })
  // }


  // executeAction() {
  //   console.log("action config available is:", this.widgetMeta)
  //   console.log("page model", this.pageModel)

  //   let actionConfig = this.widgetMeta.actionConfig
  //   let sourceMap = actionConfig?.sourceMap

  //   if (actionConfig.boxObjectFunction == 'create') {
  //     let record: any = {}
  //     sourceMap.mappedAttributeNames.forEach(attr => {

  //       //get the panel id
  //       let panelId = sourceMap[attr].panelId

  //       //get the widget name
  //       let widgetName = sourceMap[attr].widgetName

  //       //get the widget name
  //       let widgetId = sourceMap[attr].widgetId

  //       //get the value
  //       let value = this.pageModel[panelId][widgetId].value

  //       //insert in the record
  //       record[attr] = value
  //     });

  //     console.log("record object generated", record)

  //     this.boxService.createAny(actionConfig.boxId, actionConfig.boxObject, record)
  //       .then(res => {
  //         console.log("create any response: ", res)
  //       })
  //       .catch(err => {
  //         console.log("error occured in create any", err)
  //       })
  //   }
  // }

  domEventChange(event: any) {
    console.log("dom event changed", event.value)
  }
}

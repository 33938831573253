<spinner [center]="true" size="la-2x" color="grey" type="ball-triangle-path"></spinner>

<div>
    <div fxLayout="row" class="mat-h2" fxLayoutAlign="start center">
      Headers
    </div>
    <div fxLayout="row" *ngFor="let data of headers; let i = index" fxLayoutAlign="start center" fxLayoutGap="5">
        <div fxFlex="30">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Key</mat-label>
            <input matInput placeholder="Key" (ngModelChange)="inputAdded($event)" [(ngModel)]="data.__id" class="input-field">
          </mat-form-field>
        </div>
        <div fxFlex="60" >
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Value</mat-label>
            <input matInput placeholder="Value" (ngModelChange)="inputAdded($event)" [(ngModel)]="data.value" class="input-field">
          </mat-form-field>
        </div>
        <app-field-picker  [inputFieldMap]="fieldMap" fxLayoutAlign="end start"
            (selectedFields)="insertCursor($event, data)">
        </app-field-picker>
        <div fxLayoutAlign="start start" fxFlex="10" >
          <button mat-icon-button matTooltip="Delete header" (click)="deleteHeader(i)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" *ngIf="isDynamicData">
      <button style="cursor: pointer;" (click)="addNewHeader()" color="primary" mat-stroked-button matTooltip="Add query parameters">
        <mat-icon>add_circle_outline</mat-icon>
        Add Header
      </button>
    </div>
</div>



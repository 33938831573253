<div class="cover" fxFlexFill fxLayout="row wrap" fxLayoutAlign="flex-start center">
  <div class="validator-section">
    <div *ngIf="validatorOptions.length" style="margin-left: 5%;">
      <mat-slide-toggle [(checked)]="isValidatorChecked" (change)="showValidator($event.checked)">
        Validations
      </mat-slide-toggle>
    </div>
  </div>

  <div *ngIf="isValidatorChecked" style="width: 90%;">
    <div *ngFor="let validator of selectedValidators; let i = index" class="validator-item">
      <div class="validator-header">
        <mat-form-field class="full-width">
          <mat-label>Select Validation</mat-label>
          <mat-select [(value)]="selectedValidators[i]" (selectionChange)="onValidatorChanged($event.value, i)">
            <mat-option *ngFor="let option of validatorOptions" [value]="option">
              {{ option.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
    
        <button mat-icon-button color="warn" (click)="removeValidation(i)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    
      <div *ngIf="selectedValidators[i]?.config?.inputParams" class="validator-params">
        <div *ngFor="let param of selectedValidators[i].config.inputParams.params">
          <mat-form-field class="full-width">
            <mat-label>{{ selectedValidators[i].config.inputParams[param].displayName }}</mat-label>
            <input 
              matInput 
              [type]="selectedValidators[i].config.inputParams[param].type === 'number' ? 'number' : 'text'"
              [(ngModel)]="selectedValidators[i].config.inputParams[param].value"
              (ngModelChange)="onParamChange(i, param, $event)">
          </mat-form-field>
        </div>
      </div>
    
    </div>

    <!-- Add New Validation Button -->
    <div fxLayout="row" fxLayoutAlign="end center">
      <button class="small-rounded-btn" mat-stroked-button color="primary" (click)="addNewValidation()">
        <mat-icon>add_circle_outline</mat-icon> Add Validation
      </button>
    </div>
  </div>

  <div [ngSwitch]="dataConfigType">
    <div *ngSwitchCase="'chart-data-source'">
      <chart-data-config [widgetMeta]="widgetMeta" (newChartDataSource)="chartDataSourceChanged($event)"></chart-data-config>
    </div>
    <div *ngSwitchCase="'selection-type-widgets'">
      <selection-widgets-options-config [widgetMeta]="widgetMeta" (newMeta)="newOptions($event)"></selection-widgets-options-config>
    </div>
    <div *ngSwitchDefault>
      <app-select-box
        [oldValue]="widgetMeta.dataBindConfig ? widgetMeta.dataBindConfig.boxName : ''"
        (boxSelectionError)="boxSelectionError($event)"
        (boxInput)="boxSelected($event)"
      >
      </app-select-box>
      <small *ngIf="isBoxConfigError" style="color: red;">{{ boxConfigError.error.message | json }}</small>

      <app-select-box-object
        *ngIf="(isBoxSelected && canGetBoxObjects) || !firstHit"
        [boxId]="selectedBoxId"
        [connectionId]="selectedConnectionId"
        [oldValue]="widgetMeta.dataBindConfig ? widgetMeta.dataBindConfig.boxObjectId : ''"
        (boxObjectInput)="boxObjectSelected($event)"
        (boxObjectSelectionError)="boxObjectSelectionError($event)"
      >
      </app-select-box-object>
      <small *ngIf="isBoxObjectConfigError" style="color: red;">{{ boxObjectConfigError?.error.message | json }}</small>

      <div *ngIf="isOptionsToCollect" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="1rem">
      <mat-form-field *ngFor="let option of attributeOptions">
        <mat-label>{{ option.name }}</mat-label>
        <input matInput [(ngModel)]="option.value" [required]="option.required">
      </mat-form-field>
      <button mat-stroked-button color="primary" (click)="callGetAttributes()"><mat-icon>east</mat-icon></button>
      </div>


      <mat-form-field *ngIf="isAttributesReady" fxFlexFill>
      <mat-label>Select attribute</mat-label>
      <input
        #attributeInput
        type="text"
        aria-label="attribute autocomplete"
        matInput
        [formControl]="selectAttributeControl"
        [matAutocomplete]="auto"
      >
      <button *ngIf="attributeInput.value" matSuffix mat-icon-button aria-label="Clear" (click)="selectAttributeControl.patchValue('')">
        <mat-icon>close</mat-icon>
      </button>
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="attributeSelected($event);">
        <mat-option *ngFor="let attr of filteredAttributes | async" [value]="attr">
          <span>{{attr.__id}}</span>
        </mat-option>
      </mat-autocomplete>
      </mat-form-field>
    </div>
  </div>

  <div fxLayout="row">
    <div fxLayoutAlign="center center" *ngIf="isReadyToSave">
      <button
        fxLayoutAlign="center center"
        matButton
        mat-raised-button
        color="primary"
        (click)="saveDataConfig()"
      >
        Save
      </button>
    </div>
  </div>
</div>
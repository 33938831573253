<div [ngSwitch]="widgetMeta.config.value.periodType" class="container">
  <mat-form-field *ngSwitchCase="'date'" [appearance]="appearance" fxFlex="95%">
    <mat-label>{{ widgetMeta.config.placeholder.value }}</mat-label>
    <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
      <input
        matStartDate
        formControlName="start"
        placeholder="Start date"
        (dateChange)="rangeInputDetected()"
      />
      <input
        matEndDate
        formControlName="end"
        placeholder="End date"
        (dateChange)="rangeInputDetected()"
      />
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>

    @if (range.controls.start.hasError('matStartDateInvalid')) {
    <mat-error>Invalid start date</mat-error>
    } @if (range.controls.end.hasError('matEndDateInvalid')) {
    <mat-error>Invalid end date</mat-error>
    }
  </mat-form-field>
  <div *ngSwitchCase="'time'" fxLayout="row" fxLayoutGap="5px" class="container">
    <mat-form-field [appearance]="appearance" fxFlex="95%">
      <mat-label>Start Time</mat-label>
      <input
        matInput
        type="time"
        onfocus="this.showPicker()"
        [name]="widgetMeta.name"
        [required]="isRequired"
        [(ngModel)]="widgetMeta.config.value.value.start"
        (change)="rangeInputDetected()"
        #timeStartInput="ngModel"
    />
    </mat-form-field>
    <mat-icon class="icon-align">remove</mat-icon>
    <mat-form-field [appearance]="appearance" fxFlex="95%">
      <mat-label>End Time</mat-label>
      <input
        matInput
        type="time"
        onfocus="this.showPicker()"
        [name]="widgetMeta.name"
        [required]="isRequired"
        [(ngModel)]="widgetMeta.config.value.value.end"
        (change)="rangeInputDetected()"
        #timeEndInput="ngModel"
    />
    </mat-form-field>
  </div>
  <div
    class="container"
    *ngSwitchCase="'datetime'"
    fxLayout="row"
    fxLayoutGap="5px"
    [formGroup]="range"
  >
    <mat-form-field [appearance]="appearance">
      <mat-label>Start Date Time</mat-label>
      <input
        matInput
        onfocus="this.showPicker()"
        type="datetime-local"
        formControlName="start"
        [required]="isRequired"
        (input)="rangeInputDetected()"
      />
    </mat-form-field>
    <mat-icon class="icon-align">remove</mat-icon>
    <mat-form-field [appearance]="appearance">
      <mat-label>End Date Time</mat-label>
      <input
        matInput
        onfocus="this.showPicker()"
        type="datetime-local"
        formControlName="end"
        [required]="isRequired"
        (input)="rangeInputDetected()"
      />
    </mat-form-field>
  </div>
  <div *ngSwitchDefault></div>
</div>

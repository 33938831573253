import { Injectable } from "@angular/core";
import { ApplicationAction } from "./ApplicationAction";
import { BaseAction } from "./BaseAction"
import { NavigationAction } from "./NavigationAction";
import { WidgetAction } from "./WidgetAction";
import { FlowAction } from "./FlowAction";
import { ValidationService } from "src/app/shared/services/validation.service";
import { HttpCacheService } from "src/app/core/services/HttpCacheService";
import { ActionServiceUtility } from "./ActionServiceUtility";
import { ContextDataService } from "src/app/shared/services/context-data.service";


@Injectable({
    providedIn: 'root'
  })


export class ActionManager {

    constructor(
        public navigationAction: NavigationAction,
        public applicationAction: ApplicationAction,
        public widgetAction : WidgetAction,
        public flowAction: FlowAction,
        private validationService: ValidationService,
        private httpCacheService: HttpCacheService,
        private actionServiceUtility: ActionServiceUtility,
        private contextDataService: ContextDataService
    ){}


    registry = {
        navigation: this.navigationAction,
        application: this.applicationAction,
        widget: this.widgetAction,
        flow: this.flowAction
    }
    getAction(type: string, options?: any): BaseAction {
        // switch(type){
        //     case "navigation": {
        //         return this.navigationAction;
        //     }
        // }
        let actionService = this.registry[type];
        return actionService;
        // if (!actionFunction) throw "No action available: " + type;
        // return actionFunction(options);
        // return ActionRegistry.getAction(type, options);
    }

    getActions(){
        let result: any[] = []
        var keys =  Object.keys(this.registry);
        for(var k in keys){
            var macroDescriptor = this.getAction(keys[k]).getActionDetail();
            result.push(macroDescriptor)
        }
        return result;
    }

    async executeActions(actions, event, options?){

      // validate mapped widget's
      // let validity = this.validationService.checkValidation(actions)
      // if(!validity) return

      var actionResponse = [];
      let index = 0;
      this.contextDataService.clearContext();

      for (let key in actions){
        if(actions[key]?.active === false) continue;
        let isConditionSatisfy = await this.actionServiceUtility.checkActionCondition(actions[key], options)
        console.log("isConditionSatisfy", isConditionSatisfy);
        var res;
        let action = actions[key];
        action.code = `action_${index + 1}`;
        if(isConditionSatisfy) res = await this.getAction(action.action).doAction(action, event, options);
        else res = {status: "SKIPPED", message: "Conditions not matched"}
        if(res) actionResponse.push(res?.status);
        index++;
      }

      //clearing all stored box list cache
      this.httpCacheService.deleteCacheBasedOnKeyPrefix("box_get_");
      if(actionResponse.length) return actionResponse

    }
}

<div
  class="outerCover"
  *ngIf="builderMode"
  [class.selectedNow]="selectedWidgetId === widgetMeta.id"
  [class.hoveredNow]="selectedWidgetId === widgetMeta.id? false: hoveredNow"
  (mouseenter)="widgetMouseenter()"
  (mouseleave)="widgetMouseleave()"
  (click)="onClick()"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
>
  <widget-drag-handle [class.invisible]="(selectedWidgetId !== widgetMeta.id && !hoveredNow)"></widget-drag-handle>
  <div class="innerCover" [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'">
    <!-- <div class="widget-title" *ngIf="widgetMeta.config.showTitle?.value || true" [ngStyle]="styles">{{ widgetMeta.config.title?.value || "" }}</div> -->
    <inline-title [widgetMeta]="widgetMeta" [builderMode]="builderMode" [styles]="styles" (widgetTitleChanged)="newWidgetMeta.emit(widgetMeta)"></inline-title>

    <mat-form-field
      class="input"
      (mousedown)="$event.stopPropagation()"
      [appearance]="widgetMeta.config.appearance && widgetMeta.config.appearance.value ? widgetMeta.config.appearance.value : 'standard'"
    >
      <mat-label *ngIf="!widgetMeta.config.title?.show || !widgetMeta.config.title?.value?.length">{{ widgetMeta.config.placeholder.value }}</mat-label>
      <input
        matInput
        type="number"
        (input)="emitUserInput($event)"
        [(ngModel)]="widgetMeta.config.value.value"
        [placeholder]="widgetMeta.config.placeholder.value"
      >
    </mat-form-field>
  </div>
</div>


<div class="outerCover" *ngIf="!builderMode && (!widgetMeta.config.hidden || !widgetMeta.config.hidden.value)" (click)="onClick()"
  [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
>
  <div class="innerCover" *ngIf="!widgetMeta.config.viewOnly?.value" [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'">
    <!-- <div class="widget-title" [ngStyle]="styles">{{ widgetMeta.config.title?.value || "" }}</div> -->
    <inline-title [widgetMeta]="widgetMeta" [builderMode]="builderMode" [styles]="styles"></inline-title>

    <mat-form-field
      class="input"
      (mousedown)="$event.stopPropagation()"
      [appearance]="widgetMeta.config.appearance && widgetMeta.config.appearance.value ? widgetMeta.config.appearance.value : 'standard'"
    >
      <mat-label *ngIf="!widgetMeta.config.title?.show || !widgetMeta.config.title?.value?.length">{{ widgetMeta.config.placeholder.value }}</mat-label>
      <input
        matInput
        type="number"
        [formControl]="fc"
        (input)="emitUserInput($event)"
        [(ngModel)]="widgetMeta.config.value.value"
        [placeholder]="widgetMeta.config.placeholder.value"
      >
      <mat-error *ngIf="fc.invalid && fc.hasError('required')">This field is required</mat-error>
      <mat-error *ngIf="fc.invalid && customErrorMessage">{{customErrorMessage}}</mat-error>
    </mat-form-field>
  </div>

  <div *ngIf="widgetMeta.config.viewOnly?.value"
    class="innerCover label-z-index content" [ngStyle]="styles">
    <mat-label [ngStyle]="styles">{{ widgetMeta.config?.value?.value }}</mat-label>
  </div>
</div>

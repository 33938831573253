
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TokenUtil } from '../core/services/TokenUtil.service';
import { AuthServiceService } from '../shared/services/auth-service.service';
import { HttpClient } from '@angular/common/http';
import { ConnectionService } from '../modules/organization/connection.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { WidgetManager } from '../bloom/models/WidgetManager';
import { StarchService } from '../shared/services/starch.service';
import { WidgetUtilityService } from '../bloom/services/widget-utility.service';
import { BoxService } from '../shared/services/box.service';

@Injectable({
  providedIn: 'root'
})
export class FormService {
  // currentTheme: any;
  selectedPage: any;
  isPreview: boolean;
  isPreviewSuccess: boolean;
  preventAutoSave: boolean; //to stop periodic save in some cases
  validSections: { name: string; __id: string; }[];
  constructor(
    private TokenUtil: TokenUtil,
    private authService: AuthServiceService,
    private http: HttpClient,
    private connectionService: ConnectionService,
    public starchService: StarchService,
    public boxService: BoxService,
  ) { }


  userMadeChanges = new BehaviorSubject<boolean>(false);
  $userMadeChanges = this.userMadeChanges.asObservable()

  closeContextMenuRequest: any = new Subject()

  pageSelectionRequest = new BehaviorSubject<any>(null);
  pageSelectionotification = this.pageSelectionRequest.asObservable()

  formPanelDeselected = new Subject()
  $formPanelDeselected = this.formPanelDeselected.asObservable()

  formMeta = new BehaviorSubject<any>('');
  $formMeta = this.formMeta.asObservable();

  builderMode: boolean = false;
  themeSubject = new BehaviorSubject<any>({});
  capturedScreenshots: any = {
    inProgress: false,
    banner: "",
    screenshots: {}
  }

  conditionalSection: any = {
    nextPageConfig: "",
    backPageConfig: ""
  }

  defaultTheme: any ={
    style : {
      "background-color": "#f5f5f5",
    },
    frameconfig: {
      style: {
        "background-color": "rgb(255, 255, 255, 100)",
      },
      "background-color": "#ffffff",
      opacity: 100,
      margin: {left: 15, right: 85, top: 2, bottom: 98}}
  };

  capturePageScreenShots = new BehaviorSubject<boolean>(false);
  $capturePageScreenShots = this.capturePageScreenShots.asObservable()




  /*
    generates the conditional based next button and back button action config
  */
  generateConditionalButtons(curSection, nextSectionPageCode){
    if(!nextSectionPageCode) nextSectionPageCode = "_next";
    let formMeta = this.formMeta?.value;
    let sections = formMeta?.sections;
    let previousPageOfNextPageIndex = -1; //take the next page actions
    let backActionConfig = this.genBackActionConfig(curSection.code); //take current page as next back page

    //cuurent action button
    let sectionActionPanel = curSection.panels[curSection.panels.length - 1];
    let sectioncolId = sectionActionPanel.layoutMap.list[0]
    let sectionrowId = sectionActionPanel.layoutMap[sectioncolId].list[0];
    let buttonsCount = sectionActionPanel.layoutMap[sectioncolId][sectionrowId]['elements']?.length;
    let actionButton = sectionActionPanel.layoutMap[sectioncolId][sectionrowId]['elements'][buttonsCount - 1];

    //if page is not next and submit, find the previos page to get next button config
    if(!["_next", "_submit"].includes(nextSectionPageCode)){
      for (let i = 0; i < sections.length; i++) {
        const sec = sections[i];
        if(sec.code === nextSectionPageCode) {
          previousPageOfNextPageIndex = i - 1;
          break;
        };
      }
      let previousPageOfNextPageSec = sections[previousPageOfNextPageIndex];
      let nextButtonFromSection = this.getNextButtonFromSection(previousPageOfNextPageSec);
      this.conditionalSection.nextPageConfig = nextButtonFromSection.actionConfig;
      this.conditionalSection.backPageConfig = backActionConfig;
      actionButton.config.buttonText.value = "Next";
    } else if (nextSectionPageCode == "_submit"){
      let submitSection = sections[sections.length - 1];
      let nextButtonFromSection = this.getNextButtonFromSection(submitSection);
      this.conditionalSection.nextPageConfig = nextButtonFromSection.actionConfig;
      this.conditionalSection.backPageConfig = backActionConfig;
      actionButton.config.buttonText.value = "Submit";
    } else if (nextSectionPageCode == "_next"){
      actionButton.config.buttonText.value = "Next";
      this.conditionalSection.nextPageConfig = null;
      this.conditionalSection.backPageConfig = null;
    }
    console.log("[FORM-SECTION] this.conditionalSection", this.conditionalSection);
    return this.conditionalSection;
  }


  /*
    get action config if conditional config exists
  */
  getSectionButtonAction(widget){
    let curSection = this.pageSelectionRequest.value;
    console.log("[FORM-SECTION] curSection", curSection)

    //logic to find which button is clicked
    let curSectionButtonPanel = curSection.panels[curSection.panels.length - 1];
    curSectionButtonPanel = JSON.parse(JSON.stringify(curSectionButtonPanel));
    let colId = curSectionButtonPanel.layoutMap.list[0]
    let rowId = curSectionButtonPanel.layoutMap[colId].list[0];

    let totalButtons = curSectionButtonPanel.layoutMap[colId][rowId]['elements']?.length;
    let curBackButton

    // console.log("[FORM-SECTION] totalButtons", totalButtons)

    //if total buttons where two, then back button available
    if(totalButtons == 2) {
      // console.log("[FORM-SECTION] curBackButton in", curSectionButtonPanel.layoutMap[colId][rowId]['elements'])
      curBackButton = curSectionButtonPanel.layoutMap[colId][rowId]['elements'][0];
    }

    // console.log("[FORM-SECTION] curBackButton", curBackButton)

    let curNextButton = curSectionButtonPanel.layoutMap[colId][rowId]['elements'][totalButtons - 1];

    let conditionalSection  = JSON.parse(JSON.stringify(this.conditionalSection));
    // console.log("[FORM-SECTION] conditionalSection", conditionalSection)
    let result;
    if(widget.id == curNextButton.id && conditionalSection.nextPageConfig){ //next button clicked
      result = conditionalSection.nextPageConfig;
      this.conditionalSection.nextPageConfig = null; //removing set config
    } else if(curBackButton && curBackButton.id == widget.id && conditionalSection.backPageConfig) { //back button clicked
      // console.log("[FORM-SECTION] in", conditionalSection)
      result = conditionalSection.backPageConfig;
      this.conditionalSection.backPageConfig = null; //removing set config
    }
    console.log("[FORM-SECTION] next action", result);
    return result;
  }

  getNextButtonFromSection(section){
    let sectionActionPanel = section.panels[section.panels.length - 1];
    let sectioncolId = sectionActionPanel.layoutMap.list[0]
    let sectionrowId = sectionActionPanel.layoutMap[sectioncolId].list[0];
    let buttonsCount = sectionActionPanel.layoutMap[sectioncolId][sectionrowId]['elements']?.length;
    let button = sectionActionPanel.layoutMap[sectioncolId][sectionrowId]['elements'][buttonsCount - 1];
    var currentSectionsNextButton = button ? JSON.parse(JSON.stringify(button)) : null;
    return currentSectionsNextButton;
  }

  genBackActionConfig(page){
    let actionConfig = {actions: []}
    let backActionConfig = this.genNavAction();
    backActionConfig.actionMap.page = page;
    actionConfig.actions.push(backActionConfig);
    return actionConfig;
  }

  genNavAction(){
    return {
      "event": "click",
      "action": "navigation",
      "formSuccessAction": true,
      "actionMap": {
        "mapping": [],
        "parameters": [],
        origin: "formpanel",
        page: "",
        "pageType": "form_section"
      }
    }
  }

  setConditionPages(){
    let formMeta = this.formMeta?.value;
    let currentSection = this.pageSelectionRequest?.value;
    let validSections = [{
      name: "Continue to next section",
      __id: "_next"
    }];
    formMeta?.sections?.forEach(section => {
      let obj = {
        name: "Go to " + section.name,
        __id: section.code
      }
      if(currentSection.code != section.code) validSections.push(obj);
    });
    validSections.push({
      name: "Submit form",
      __id: "_submit"
    });
    this.validSections = validSections;
    return this.validSections;
  }

  createFormPanelConfig(baseMap: any, boxObjectId: string, origin: string = 'base'){
    let basicConfig:any = {
      "type": "formpanel",
      "layout": "flex-start",
      "widgets": [],
      "boxObjectId": boxObjectId,
      "formAttributes": [],
      "mode": "update",
      "attributeOptions": [],
      "actionFnOptions": [],
      "getFnOptions": [],
      "formPanelTitle": `Starch - ${boxObjectId} - Form`,
      "submitButtonTitle": "Submit",
      "primaryAttribute": {},
    }

    if(origin == "base"){
      basicConfig.boxId = "starch";
      basicConfig.baseMap = {
        "box_id": baseMap.storage_box,
        "base_id":  baseMap._id
      }
      basicConfig.baseId = baseMap._id;
      basicConfig.boxConfigToken = baseMap.storage_token;
    } else {
      // basicConfig.boxId = this.boxId;
      // basicConfig.boxName = this.baseMap?.options?.box_name || "";
      // basicConfig.connectionId = this.baseMap._id;
    }
    return basicConfig;
  }

  filterAttributesForForm(boxAttributeRes){
    let attributes = [];
    boxAttributeRes.forEach(element => {
      if(!element.parent && !element.hasOwnProperty('writable') && ['string', 'number', 'date', 'datetime'].includes(element.dataType)){
        element['enabled'] = true;
        element.editable = true;
        element.widgetType = "input";
        if(element.primary){
          element.editable = false;
        } else if(element.relation){
          element.widgetType = "autocomplete";
        }
        attributes.push(element)
      }
    });
    return attributes;
  }

  async create_form(body?: any) {
    console.log("printing", body)
    var options = { headers: { Authorization: `PreAuthenticatedToken ${this.connectionService.preAuthenticatedToken}` } }

    var url = `${environment.SERVER_BASE_URL}/form`
    // var url = `http://localhost:8081/api/bloom`

    try {
      let response: any = await this.http.post(url, body, options).toPromise();
      console.log("create form response ---->", response);
      return response.data[0]
    } catch (e) {
      console.error("[BOX-HTTP] Error on create form:", e)
      throw e;
    }
  }


  /**
   *
   * @param code
   * @param ownerEmail
   * @param status: "published" || "draft"
   * @returns
   */
  async getFormByCode(code: any, ownerEmail?: any, status: string = "draft") {
    let token = ownerEmail ? this.connectionService.getPreAuthenticatedTokenForEmail(ownerEmail) : this.connectionService.preAuthenticatedToken;
    var options = { headers: { Authorization: `PreAuthenticatedToken ${token}`, "Cache-Control": 'no-cache' } }

    let filter = `code=${code}|string,status=${status}|string`;

    var url = `${environment.SERVER_BASE_URL}/form?filter=${filter}`
    try {
      console.log("get form by code, url:", url, "options", options)
      let response: any = await this.http.get(url, options).toPromise();
      if(!this.builderMode) {
        let createrEmail = response?.data[0]?.created_by;
        this.connectionService.preAuthenticatedToken = this.connectionService.getPreAuthenticatedTokenForEmail(createrEmail);
      }
      console.log("get form by code, response ---->", response);
      return response?.data[0] || null;
    } catch (e) {
      console.error("[BOX-HTTP] Error on get form by code:", e)
      throw e;
    }
  }

  async getFormByFilter(filter:any, ownerEmail?: any) {
    let token = ownerEmail ? this.connectionService.getPreAuthenticatedTokenForEmail(ownerEmail) : this.connectionService.preAuthenticatedToken;
    var options = { headers: { Authorization: `PreAuthenticatedToken ${token}`, "Cache-Control": 'no-cache' } }
    var url = `${environment.SERVER_BASE_URL}/form?filter=${filter}`
    try {
      console.log("get form by filter, url:", url, "options", options)
      let response: any = await this.http.get(url, options).toPromise();
      console.log("get form by filter, response ---->", response);
      return response?.data[0] || null;
    } catch (e) {
      console.error("[BOX-HTTP] Error on get form by filter:", e)
      throw e;
    }
  }


  /**
   *
   * @param wid
   * @param pageNumber
   * @param pageSize
   * @param status: draft | published
   * @returns
   */
  async getFormsByWorkspace(wid: any, pageNumber: number = 1, pageSize: number = 100, status: string = '') {
    var options = { headers: { Authorization: `PreAuthenticatedToken ${this.connectionService.preAuthenticatedToken}` } }

    let filter = "workspace_id=" + wid + "|string"

    if(status) filter += `,status=${status}`

    let page = `${pageNumber}|${pageSize}|100`
    // let sort = "modified_at=DESC"
    let sort = ""

    var url = `${environment.SERVER_BASE_URL}/form?filter=${filter}&page=${page}`
    if(sort?.length) url += `&sort=${sort}`
    // var url = `http://localhost:8081/api/bloom?filter=${filter}&sort=${sort}&page=${page}`

    try {
      console.log("hitting url", url)
      let response: any = await this.http.get(url, options).toPromise();
      console.log("get forms by workspace ID, response ---->", response);
      return response.data
    } catch (e) {
      console.error("[BOX-HTTP] Error on get forms by workspace:", e)
      throw e;
    }
  }

  async getFormsTemplate(pageNumber: number = 100,  pageSize: number = 1) {
    var options = { headers: { Authorization: `PreAuthenticatedToken ${this.connectionService.preAuthenticatedToken}` } }

    let filter = "template_status=published|string,template=true|boolean"

    let page = `${pageNumber}|${pageSize}|100`
    let sort = ""

    var url = `${environment.SERVER_BASE_URL}/form?filter=${filter}&page=${page}`
    if(sort?.length) url += `&sort=${sort}`

    try {
      let response: any = await this.http.get(url, options).toPromise();
      console.log("get forms template, response ---->", response);
      return response
    } catch (e) {
      console.error("[BOX-HTTP] Error on get forms by workspace:", e)
      throw e;
    }
  }


  async updateForm(data?: any) {
    var options = { headers: { Authorization: `PreAuthenticatedToken ${this.connectionService.preAuthenticatedToken}` } }
    var url = `${environment.SERVER_BASE_URL}/form`;
    if(!data) data = this.formMeta.value

    // data = this.formContentService.clearWidgetValuesInPage(JSON.parse(JSON.stringify(data)))

    console.log("update form payload", data)
    try {
      let response: any = await this.http.put(url, data, options).toPromise();
      console.log("update response ---->", response);
      return response.data[0]
    } catch (e) {
      console.error("[BOX-HTTP] Error on update form:", e)
      throw e;
    }
  }


  async deleteForm(form?: any) {
    console.log("printing in delete form", form)

    var options = { headers: { Authorization: `PreAuthenticatedToken ${this.connectionService.preAuthenticatedToken}` } }
    var url = `${environment.SERVER_BASE_URL}/form/${form._id}`
    // var url = `http://localhost:8081/api/bloom/${bloom._id}`

    for (let i = 0; i < form.panels?.length; i++) {
      const panel = form.panels[i];
      // find the form panel containing baseId
      if(panel.type == 'formpanel' && panel.baseId){
        var boxId = panel.baseMap?.box_id;
        var boxObjectId = panel.boxObjectId;
        var boxToken = panel.boxConfigToken;
        break
      }
    }

    try {
      await this.boxService.deleteBoxObject(boxId, boxObjectId, boxToken);
      let response: any = await this.http.delete(url, options).toPromise();
      console.log("delete response ---->", response);
      return response
    } catch (e) {
      console.error("[BOX-HTTP] Error on form delete:", e)
      throw e;
    }
  }

  getSubmitButton(page: any, form?: any){
    console.log("page in", page)
    let panels = page.panels;
    let formPanel = this.getFormPanel(form || page);
    let result: { widget: any, widgetId?: any, panelId?: any, layoutId?: any, layoutRowId?: any, widgetIndex?: any } = {
      widget: null,
    }
    let panelIndex, columnId, rowId, widgetIndex;
    for (let index = 0; index < panels.length; index++) {
      const element = panels[index];
      if(element.type != 'regular') continue;
      else {
        result.panelId = element.id;
        for (let i = 0; i < element?.layoutMap?.list?.length; i++) {
          let columnId = element.layoutMap.list[i];
          let column = element.layoutMap[columnId];
          result.layoutId = columnId;
          for (let j = 0; j < column?.list?.length; j++) {
            let rowId = column.list[j];
            let row = column[rowId];
            result.layoutRowId = rowId;
            for (let k = 0; k < row?.elements?.length; k++) {
              let ele = row.elements[k];
              if(ele.type == "button" && ele.id == formPanel?.submitButtonMeta?.id) {

                result['panelIndex'] = index;
                result['columnId'] = columnId;
                result['rowId'] = rowId;
                result['widgetIndex'] = k;

                result.widget = ele;
                result.widgetId = element.id;
                break;
              }
            }
            if(result.widget) break;
          }
          if(result.widget) break;
        }
      }
      if(result.widget) break;
    }
    return result;
  }

  /**
   *
   * @param section
   * @param form
   * @param submitButtonDetails : {
   *    layoutId: any,
   *    layoutRowId: any,
   *    widgetIndex: any,
   *    widget: any   // submit button widget meta
   * }
   * @param panelId
   * @param widgetIndex
   * @returns
   */
  setSubmitButtonMeta(form, submitButtonDetails){
    console.log("set submit button meta", submitButtonDetails, "in form", JSON.parse(JSON.stringify(form)))
    let submitPage = form.sections[form.sections.length - 1]
    submitPage.panels.forEach((panel, panelIndex) => {
      if (panel.id == submitButtonDetails.panelId) {
        panel.layoutMap[submitButtonDetails.layoutId][submitButtonDetails.layoutRowId].elements[submitButtonDetails.widgetIndex] = submitButtonDetails.widget
      }
    })
    console.log("returning updated form", JSON.parse(JSON.stringify(form)))
    return form
  }

  getFormPanel(form){
    let panels = form.panels;
    console.log("form", form, panels)
    let result;
    for (let index = 0; index < panels.length; index++) {
      const element = panels[index];
      if(element.type == "formpanel"){
        result = element;
        break;
      }
    }
    return result;
  }

  createEmptyFormPanel(){
    let basicConfig:any = {
      "type": "formpanel",
      "layout": "flex-start",
      "widgets": [],
      "boxObjectId": "",
      "formAttributes": [],
      "mode": "update",
      "attributeOptions": [],
      "actionFnOptions": [],
      "getFnOptions": [],
      "formPanelTitle": `New - Form`,
      "submitButtonTitle": "Submit",
      "primaryAttribute": {},
      "submitDecoupled": true,
      "hideTitle": true
    }
    return basicConfig;
  }

  createEmptyFormPanelButton() {
    let buttonWidget = WidgetManager.getWidget('button');
    let actionConfig = {
      "actions": [{
        "event": "click",
        "action": "application",
        "actionMap": {

          "mapping": [],
          successMessage: `Form data is successfully submitted!`
        }
      }]
    }

    buttonWidget.gridX = 4;
    buttonWidget.actionConfig = actionConfig;
    buttonWidget.config.buttonText.value = "Save";
    return buttonWidget;
  }

  async prepareBase(code: string, defaultAttributes?: any, isAdditionalBase: boolean = false, isCreateNewBase: boolean = false ){
    let base: any

    // get workspace and pick up default base id
    let workspace: any = await this.getWorkspace()
    console.log("current workspace", JSON.parse(JSON.stringify(workspace || {})))

    let baseId = workspace.default_base_id;

    if(isAdditionalBase && workspace?.options?.additional_form_bases?.length && !isCreateNewBase) {
      baseId =  workspace.options.additional_form_bases[workspace.options.additional_form_bases?.length - 1];
    } else if(isAdditionalBase || isCreateNewBase) baseId = null;

    if(baseId) {
      // if default base id
      base = await this.getBase(baseId)
    }

    if(!base) {
      // if no default base id, create a base, attach it to workspace
      if(!base) base = await this.createNewBase()
      console.log("new base created", base)

      // attach newly created base id to workspace
      if(!isAdditionalBase) workspace['default_base_id'] = base._id;
      else if(isAdditionalBase) { //adding additional form base
        if(!workspace?.options) workspace.options = {};
        if(!workspace.options?.additional_form_bases) workspace.options.additional_form_bases = [];
        if(!workspace.options.additional_form_bases.includes(base._id)) workspace.options.additional_form_bases.push(base._id);
      }
      // update workspace
      let token = this.connectionService.getPreAuthenticatedTokenForEmail(workspace.created_by);
      let res = await this.connectionService.updateWorkspace(workspace, token)
      console.log("workspace updated", res)
    }

    try {
      // create base object
      var baseObject = await this.starchService.createBaseObject(base, {object: code})
      console.log("base object created", baseObject)
    } catch(e){
      console.log("error on creating object", e)
      // if collection reached 500/500, create new base
      if(e?.error?.errorStack?.startsWith("MongoServerError: cannot create a new collection -- already using 500 collections of 500")) {
        let createBase = isAdditionalBase ? isAdditionalBase : false;
        return await this.prepareBase(code, defaultAttributes, true, createBase);
      } else throw e;
    }

    return await this.prepareAttributesAndBase(base, baseObject, defaultAttributes);
  }

  async prepareAttributesAndBase(base, baseObject, defaultAttributes){
    let baseAttributes: any = [
      {
        name: 'Name',
        dataType: 'string',
        defaultValue: ''
      },
      {
        name: 'City',
        dataType: 'string',
        defaultValue: ''
      },
      {
        name: 'Country',
        dataType: 'string',
        defaultValue: ''
      }
    ];

    if(defaultAttributes){
      baseAttributes = [];
      defaultAttributes.forEach(element => {
        if(!element.primary) baseAttributes.push({
          name: element.__id,
          dataType: element.dataType,
          defaultValue: ''
        })
      });
    }


    // create base object attribute
    let payload = {
      attributes: baseAttributes,
      object: baseObject.__id,
      options: {
        relationObject: 'starch_relationship'
      }
    }
    let attrCreateResponse = await this.starchService.createBaseAttributes(base, payload)
    console.log("attribute created", attrCreateResponse)

    // get attributes
    let getAttrPayload = {
      object: baseObject.__id,
      options: {
        relationObject: 'starch_relationship'
      }
    }
    let attributes = await this.starchService.getBaseAttributes(base, getAttrPayload)
    console.log("attributes fetched", attributes)

    // this.spinnerService.hide();
    return {
      baseMap: base,
      object: baseObject,
      attributes: attributes
    }
  }

  getWorkspace(){
    return this.connectionService.selectedWorkSpaceMap
  }

  async getBase(id: string){
    let base = await this.starchService.getStarchBase(id)
    return base
  }

  async createNewBase(){
    console.log("create new base hit")
    let randomCode: string = await this.starchService.checkAndGetRandomCode()
    let baseMap = {
      code: randomCode,
      name: randomCode
    }
    // this.spinnerService.show();
    let base = await this.starchService.createStarchBase(baseMap);
    console.log("base created", base)
    return base
  }

  getLogoUrl() {
    let randomLogoCode: any = Math.floor(Math.random() * 10)
    randomLogoCode = randomLogoCode + 1
    if (randomLogoCode < 10) {
      randomLogoCode = '0' + randomLogoCode
    }

    let logoUrl = environment.BLOOM_LOGO_BASE_URL + randomLogoCode + '.jpg'
    return logoUrl
  }

  getImageUrl(type: string) {
    let randomCode: any = Math.floor(Math.random() * 6)
    randomCode = randomCode + 1

    let imageUrl
    if(type == 'logo'){
      // imageUrl = environment.FORM_LOGO_BASE_URL + randomCode + '.jpg'
    }else if(type == 'banner'){
      imageUrl = environment.FORM_BANNER_BASE_URL + randomCode + '.png'
    }
    return imageUrl
  }

  createFormMeta(formNameMap?: any){
    let randomCode = String(Date.now()).substring(8);

    let logoUrl = this.getLogoUrl()
    console.log("logo url", logoUrl)

    console.log("random code", randomCode)
    let form = {
      code: formNameMap?.code || 'form-' + randomCode,
      name: formNameMap?.name || 'form-' + randomCode,
      created_at: new Date().toISOString(),
      modified_at: new Date().toISOString(),
      created_by: this.authService.profile.email,
      modified_by: this.authService.profile.email,
      workspace_id: this.connectionService.selectedWorkSpace,
      logoUrl: logoUrl,
      status: 'draft',
      access_type:  "private",
      require_login: true,
      title: "New Form",
      tagline: "Customizable form based on starch"
    };
    return form
  }

  createFormPanel(baseMap: any, object: string){
    let basicConfig:any = {
      "type": "formpanel",
      "layout": "flex-start",
      "widgets": [],
      "boxObjectId": object,
      "formAttributes": [],
      "mode": "update",
      "attributeOptions": [],
      "actionFnOptions": [],
      "getFnOptions": [],
      "formPanelTitle": `${object} - Form`,
      "submitButtonTitle": "Submit",
      "primaryAttribute": {},
      "submitDecoupled": true,
      "hideTitle": true
    }

    basicConfig.boxId = "starch";
    basicConfig.baseMap = {
      "box_id": baseMap.storage_box,
      "base_id": baseMap._id
    }
    basicConfig.baseId = baseMap._id;
    basicConfig.boxConfigToken = baseMap.storage_token;

    return basicConfig;
  }

  formPanelButton(baseMap: any, object: string){
    let buttonWidget = WidgetManager.getWidget('button');
    let actionConfig = {
      "actions": [{
        "event": "click",
        "action": "application",
        "actionMap": {
          "action": `${object}/create`,
          "boxId": "starch",
          "actionMode": "save",
          "boxConfigToken": baseMap.storage_token,
          "baseMap": {
            "box_id": baseMap.storage_box,
            "base_id":  baseMap._id
          },
          "mapping": [],
          successMessage: `Form data is successfully submitted!`
        }
      }]
    }

    buttonWidget.gridX = 4;
    buttonWidget.actionConfig = actionConfig;
    buttonWidget.config.buttonText.value = "Save";
    return buttonWidget;
  }

  createNavAction(){
    return {
      "event": "click",
      "action": "navigation",
      "formSuccessAction": true,
      "actionMap": {
        "mapping": [],
        "parameters": [],
        "pageType": "form_success"
      }
    }
  }



}
